import Vue, { VNode } from "vue";
import {
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIcon,
  VList,
  VListItem,
  VSpacer,
  VSwitch,
  VChip,
  VTooltip,
} from "vuetify/lib";

const PermissionsAccordion = Vue.extend({
  data: () => ({
    changedPermissions: [] as any[],
  }),
  props: {
    content: Array,
    isEditable: Boolean,
  },
  methods: {
    getChangedPermissions() {
      return this.changedPermissions;
    },

    getPermissionGroupStatistic(group: any): any {
      const totalCount = group.permissions.length;
      const activeCount =
        group.permissions.filter(({ hasRule }: any) => hasRule).length || 0;

      const color = this.getStatisticColor(activeCount, totalCount);

      return { totalCount, activeCount, color };
    },

    getStatisticColor(activeCount: number, totalCount: number) {
      if (activeCount === totalCount) return "success";
      if (activeCount === 0) return "error";
      return "accent";
    },
  },

  render(): VNode {
    return (
      <VExpansionPanels class="ml-3">
        {this.content?.map((group: any) => {
          const statistic = this.getPermissionGroupStatistic(group);

          return (
            <VExpansionPanel>
              <VExpansionPanelHeader class="secondary primary--text font-weight-bold">
                <span>
                  {!this.isEditable && (
                    <span class="d-inline-block" style="min-width: 75px;">
                      <VChip small color={statistic.color} class="mr-3">
                        {`${statistic.activeCount} / ${statistic.totalCount}`}
                      </VChip>
                    </span>
                  )}
                  {group.name}
                </span>
              </VExpansionPanelHeader>
              <VExpansionPanelContent>
                <VList class="pb-0">
                  {group.permissions.map(
                    ({ name, hasRule, description }: any) => (
                      <VListItem class="px-0">
                        <VTooltip
                          top
                          scopedSlots={{
                            activator: ({ on, attrs }: any) => (
                              <VIcon
                                color="accent"
                                class="mr-2"
                                {...{ on, attrs }}
                              >
                                mdi-information
                              </VIcon>
                            ),
                          }}
                        >
                          {description || "No description available"}
                        </VTooltip>

                        {name}
                        <VSpacer />
                        {this.isEditable ? (
                          <VSwitch
                            class="mt-0"
                            vModel={hasRule}
                            onChange={(val: string) =>
                              this.changedPermissions.push({
                                name,
                                hasRule: val,
                              })
                            }
                          />
                        ) : (
                          <span>
                            {hasRule ? (
                              <VIcon color="success">mdi-check-circle</VIcon>
                            ) : (
                              <VIcon color="error">mdi-close-circle</VIcon>
                            )}
                          </span>
                        )}
                      </VListItem>
                    )
                  )}
                </VList>
              </VExpansionPanelContent>
            </VExpansionPanel>
          );
        })}
      </VExpansionPanels>
    );
  },
});

export default PermissionsAccordion;
