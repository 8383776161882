import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { hasMinLength, isEmail, isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { PropType, VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import TenantSelect from "../TenantSelect";
import { Namespaces, Permissions } from "@/constants/permissions";

const UserCreateForm = Vue.extend({
  props: {
    idTenant: [String] as PropType<any>,
  },

  data: () => ({
    fields: {
      username: "",
      firstname: "",
      idTenant: "" as any,
      lastname: "",
      email: "",
      password: "",
    },
    isPasswordHidden: true,
    isFormValid: true,
  }),

  methods: {
    async handleSubmit() {
      const { idTenant, ...fields } = this.fields;

      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.TENANT_USER,
        values: fields,
        actionId: Actions.TENANT_USER_CREATE,
        params: { idTenant },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.USER_CREATE, {});

        this.$router.push({
          name: Routes.USER_DETAIL_BASIC,
          params: { idUser: data.id },
        });

        pushNotify(Notify.SUCCESS, "User created successfully");
      }
    },
  },

  created() {
    this.idTenant ? (this.fields.idTenant = parseInt(this.idTenant)) : null;
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.username}
          label="User name"
          rules={[isRequired]}
        />
        <TenantSelect
          vModel={this.fields.idTenant}
          options={{ rules: [isRequired] }}
          permission={[Namespaces.ACCESS_MANAGEMENT, Permissions.EDIT_ACCOUNT]}
        />
        <VTextField
          vModel={this.fields.firstname}
          label="First name"
          rules={[isRequired]}
        />
        <VTextField
          vModel={this.fields.lastname}
          label="Last Name"
          rules={[isRequired]}
        />
        <VTextField
          vModel={this.fields.email}
          label="Email"
          rules={[isRequired, isEmail]}
        />
        <VTextField
          vModel={this.fields.password}
          label="Password"
          type={this.isPasswordHidden ? "password" : "text"}
          rules={[isRequired, (val: string) => hasMinLength(val, 7)]}
          appendIcon={this.isPasswordHidden ? "mdi-eye" : "mdi-eye-off"}
          on={{
            "click:append": () =>
              (this.isPasswordHidden = !this.isPasswordHidden),
          }}
        />
      </VForm>
    );
  },
});

export default UserCreateForm;
