import Vue, { VNode } from "vue";
import { mapActions } from "vuex";

import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Dropdowns from "@/constants/dropdowns";
import ExternalLinks from "@/constants/externalLinks";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import useFlowActions from "@/hooks/useFlowActions";
import { Actions, Modules } from "@/models/store";

import CallScriptsDataGrid from "../CallScriptsDataGrid";

const ComponentsDataGrid = Vue.extend({
  data: () => ({
    extendedConfig: {
      title: "Components",
      buttons: [
        {
          title: "New component",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.COMPONENT, [
            Permissions.EDIT,
          ]),
          action: () =>
            toggleDialog(Dialogs.FLOW_CREATE, { isComponent: true }),
        },
      ],
      rowActions: (row: any) => ({
        primary: {
          icon: "mdi-file-tree",
          title: "Design",
          isAllowed: hasUserPermissions(
            Namespaces.COMPONENT,
            [Permissions.VIEW],
            row.idTenant
          ),
          href: `${ExternalLinks.DESIGNER}?scenarioId=${row.id}`,
        },
        secondary: [
          {
            icon: "mdi-eye",
            title: "Detail",
            isAllowed: hasUserPermissions(
              Namespaces.COMPONENT,
              [Permissions.VIEW],
              row.idTenant
            ),
            route: {
              name: Routes.FLOW_DETAIL_BASIC,
              params: { idCallScript: row.id },
            },
          },
          ...useFlowActions({ ...row, isComponent: true }),
        ],
      }),
    },
  }),

  methods: {
    ...mapActions(Modules.DROPDOWNS, [Actions.DROPDOWN_DATA_FETCH]),
  },

  created() {
    this.DROPDOWN_DATA_FETCH({
      url: ApiEndpoints.COMPONENT_TYPES_LIST,
      dropdown: Dropdowns.COMPONENT_TYPES,
      isValueNumber: true,
    });
  },

  render(): VNode {
    return (
      <CallScriptsDataGrid
        extendedConfig={this.extendedConfig}
        fetchProps={{ isComponent: true }}
        isFavoriteEnabled={false}
        showSubtype
      />
    );
  },
});

export default ComponentsDataGrid;
