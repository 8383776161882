import Dialogs from "@/constants/dialogs";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import PermissionsAccordion from "../PermissionsAccordion";

const PermissionGroupPermissionsEditDialog = Vue.extend({
  data: () => ({
    permissions: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_PERMISSION_GROUP]),
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    permissionsData(): any {
      return this.GET_DIALOG_DATA(Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT)
        ?.data;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.PERMISSION_GROUP_PERMISSIONS_EDIT,
    ]),

    editRules() {
      const refs: any = this.$refs;
      const editedRules = refs.accordion.getChangedPermissions();

      const allPermissions: any[] = [];
      this.INSPECTED_PERMISSION_GROUP.permissions?.content?.map(
        ({ permissions }: any) =>
          permissions.map((perm: string) => allPermissions.push(perm))
      );

      editedRules?.map(({ name, hasRule }: any) => {
        const ruleIndex = allPermissions.findIndex(
          (rule) => rule.name === name
        );
        allPermissions[ruleIndex].hasRule = hasRule;
      });

      this.PERMISSION_GROUP_PERMISSIONS_EDIT({
        permissions: allPermissions,
        idRole: this.$route.params.idRole,
      });
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT}
        title="Permissions edit"
        actions={[
          {
            title: "Confirm",
            icon: "mdi-check",
            action: () => this.editRules(),
            id: Actions.PERMISSION_GROUP_PERMISSIONS_EDIT,
          },
        ]}
        width="750"
      >
        {this.permissionsData && (
          <PermissionsAccordion
            content={this.permissionsData}
            ref="accordion"
            isEditable
          />
        )}
      </Dialog>
    );
  },
});

export default PermissionGroupPermissionsEditDialog;
