import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VCol, VDivider, VRow, VSkeletonLoader } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import PermissionGroupPermissionsEditDialog from "../PermissionGroupPermissionsEditDialog";
import PermissionsAccordion from "../PermissionsAccordion";
import SectionToolbar from "../SectionToolbar";

const PermissionGroupPermissions = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_PERMISSION_GROUP]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    groupedPermissions(): any {
      const tenant: any[] = [];
      const system: any[] = [];

      this.INSPECTED_PERMISSION_GROUP.permissions?.content?.map(
        (group: any) => {
          const tenantGroup: any[] = [];
          const systemGroup: any[] = [];

          group.permissions?.map((permission: any) =>
            permission.isTenantDepended
              ? tenantGroup.push(permission)
              : systemGroup.push(permission)
          );

          tenantGroup.length > 0
            ? tenant.push({ name: group.name, permissions: tenantGroup })
            : null;
          systemGroup.length > 0
            ? system.push({ name: group.name, permissions: systemGroup })
            : null;
        }
      );

      return { tenant, system };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.PERMISSIONS_WITH_GROUPS_FETCH]),
  },

  created() {
    const idRole = this.$route.params.idRole;
    this.PERMISSIONS_WITH_GROUPS_FETCH({ idRole });
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="Tenant dependend permissions"
          actions={
            hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
              Permissions.EDIT_ROLE,
            ]) && [
              {
                icon: "mdi-pencil",
                action: () =>
                  toggleDialog(Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT, {
                    data: this.groupedPermissions.tenant,
                  }),
              },
            ]
          }
        />
        <VRow>
          <VCol col={12} md={8}>
            {this.IS_ACTION_FETCHING(Actions.PERMISSIONS_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.PERMISSIONS_GROUPS_FETCH) ? (
              <VSkeletonLoader type="list-item@5" />
            ) : (
              <PermissionsAccordion content={this.groupedPermissions.tenant} />
            )}
          </VCol>
        </VRow>

        <VDivider class="mb-5 mt-10" />

        <SectionToolbar
          title="System permissions"
          actions={
            hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
              Permissions.EDIT_ROLE,
            ]) && [
              {
                icon: "mdi-pencil",
                action: () =>
                  toggleDialog(Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT, {
                    data: this.groupedPermissions.system,
                  }),
              },
            ]
          }
        />
        <VRow>
          <VCol col={12} md={8}>
            {this.IS_ACTION_FETCHING(Actions.PERMISSIONS_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.PERMISSIONS_GROUPS_FETCH) ? (
              <VSkeletonLoader type="list-item@5" />
            ) : (
              <PermissionsAccordion content={this.groupedPermissions.system} />
            )}
          </VCol>
        </VRow>

        <PermissionGroupPermissionsEditDialog />
      </div>
    );
  },
});

export default PermissionGroupPermissions;
