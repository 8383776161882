import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";

import CsvExportDialog from "@/components/CsvExportDialog";
import DetailWithTabs from "@/components/DetailWithTabs";
import FlowDuplicateDialog from "@/components/FlowDuplicateDialog";
import FlowMakeTestCall from "@/components/FlowMakeTestCall";
import FlowRemoveDialog from "@/components/FlowRemoveDialog";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import ExternalLinks from "@/constants/externalLinks";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import useFlowActions from "@/hooks/useFlowActions";
import { Actions, Getters, Modules } from "@/models/store";

import DialLogDetailDialog from "../DialLogDetailDialog";

const FlowDetail = Vue.extend({
  name: "FlowDetail",
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),

    flowData(): any {
      const name = this.INSPECTED_FLOW?.basic?.content?.name;
      const id = this.$route.params.idCallScript;
      const languages = this.INSPECTED_FLOW?.basic?.content?.languages;
      const guid = this.INSPECTED_FLOW?.basic?.content?.guid;
      const idTenant = this.INSPECTED_FLOW?.basic?.content?.idTenant;
      const isComponent = this.INSPECTED_FLOW?.basic?.content?.isComponent;

      return { name, id, languages, guid, idTenant, isComponent };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`${this.flowData.isComponent ? "Component" : "Flow"} - ${
            this.flowData.name || ""
          }`}
          routerKey={`${this.$route.name}${this.$route.params.idCallScript}`}
          key={this.$route.params.idCallScript}
          defaultReturnRoute={
            this.flowData.isComponent ? Routes.COMPONENTS : Routes.FLOW_OVERVIEW
          }
          initDataFetch={() =>
            this.FLOW_OVERVIEW_FETCH({
              idCallScript: this.flowData.id,
            })
          }
          actions={[
            {
              icon: "mdi-file-tree",
              title: "Design",
              isAllowed: hasUserPermissions(Namespaces.CALLSCRIPT, [
                Permissions.VIEW_CALLSCRIPT,
              ]),
              href: `${ExternalLinks.DESIGNER}?scenarioId=${this.flowData.id}`,
            },
            ...useFlowActions(this.flowData),
          ]}
          tabs={[
            {
              title: "General",
              path: Routes.FLOW_DETAIL_BASIC,
            },
            ...(!this.flowData.isComponent
              ? [
                  {
                    title: "Conversation log",
                    path: Routes.FLOW_DETAIL_DIAL_LOGS,
                  },
                ]
              : []),
            {
              title: "Version history",
              path: Routes.FLOW_DETAIL_VERSION_HISTORY,
            },
          ]}
        />
        <CsvExportDialog
          name={Dialogs.FLOW_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_REPORTS_FLOW}
          prefix="flow"
          asConversationSelect
        />
        <FlowMakeTestCall />
        <FlowDuplicateDialog />
        <FlowRemoveDialog />
        <DialLogDetailDialog />
      </div>
    );
  },
});

export default FlowDetail;
