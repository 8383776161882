import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCol,
  VFileInput,
  VForm,
  VIcon,
  VRow,
} from "vuetify/lib";
import { mapGetters } from "vuex";

import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import generateErrorMsg from "@/helpers/generateErrorMsg";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";

import PackageManagerInstallResultDialog from "../PackageManagerInstallResultDialog";
import SectionToolbar from "../SectionToolbar";
import TenantSelect from "../TenantSelect";

const PackageManagerInstall = Vue.extend({
  data: () => ({
    installPackage: "",
    idTenant: null,
    resultData: null,
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    async handleSubmit() {
      if (this.installPackage) {
        const formData = new FormData();
        formData.append("file", this.installPackage);

        const { isFormSubmited, data } = await submitForm({
          actionId: Actions.PACKAGE_INSTALL,
          url: ApiEndpoints.PACKAGE_INSTALL,
          values: formData,
          params: this.idTenant ? { idTenant: this.idTenant } : null,
        });

        if (isFormSubmited) {
          this.resultData = data;
          toggleDialog(Dialogs.PACKAGE_INSTALL, {});
        } else {
          pushNotify(Notify.ERROR, generateErrorMsg(Actions.PACKAGE_INSTALL));
        }
      }
    },
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar title="Install package" />
        <VCard
          elevation="0"
          loading={this.IS_ACTION_FETCHING(Actions.PACKAGE_INSTALL)}
        >
          <VCardText>
            <VForm ref="form">
              <VRow class="mb-1">
                <VCol cols={12} md={6}>
                  <VFileInput
                    vModel={this.installPackage}
                    label="Browse file..."
                    outlined
                    prepend-icon=""
                    hide-details
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols={12} md={6}>
                  <TenantSelect
                    vModel={this.idTenant}
                    options={{ outlined: true, clearable: true }}
                  />
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
          <div class="d-flex justify-end">
            <VBtn
              onClick={() => this.handleSubmit()}
              disabled={this.IS_ACTION_FETCHING(Actions.PACKAGE_INSTALL)}
              large
              rounded
              class="tertiary white--text"
            >
              <VIcon left>mdi-package-up</VIcon>Install
            </VBtn>
          </div>
        </VCard>
        <PackageManagerInstallResultDialog resultData={this.resultData} />
      </div>
    );
  },
});

export default PackageManagerInstall;
