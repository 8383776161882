import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import { isRequired, isTime, minValue } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VAlert,
  VAutocomplete,
  VCol,
  VDivider,
  VFileInput,
  VForm,
  VMenu,
  VRow,
  VSelect,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSwitch,
  VTextField,
} from "vuetify/lib";
import FlowSelect from "../FlowSelect";
import LineSelect from "../LineSelect";
import DAYS_IN_WEEK from "@/constants/daysInWeek";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import { mapActions, mapGetters } from "vuex";
import Routes from "@/constants/routes";
import TenantSelect from "../TenantSelect";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import DateTimePicker from "../DateTimePicker";
import { formatTimezoneDateToUTC } from "@/helpers/dateAndTimeUtils";
import downloadFile from "@/helpers/downloadFile";

const CampaignCreateForm = Vue.extend({
  props: {
    prefilled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    campaignId: "",
    fields: {
      name: "",
      callScriptCode: "",
      idLine: "",
      idTenant: "",
      dow: [1, 2, 3, 4, 5],
      runAsap: true,
      numberOfCallAttemps: "2",
      retryCallAfterMinutes: "60",
      start: "",
      end: "",
      ongoing: true,
      callFromDayTime: "9:00",
      callToDayTime: "18:00",
      contactsFile: null,
      delimiter: ",",
      publicHoliday: false,
    },
    menus: {
      campaignStart: false,
      campaignEnd: false,
    },
    steps: {
      activeStep: 1,
      isStep1Valid: false,
    },
    filledCsv: {},
  }),

  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
    ...mapGetters(Modules.INSTANCES, [
      Getters.INSPECTED_TENANT,
      Getters.INSPECTED_CAMPAIGN,
    ]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    tenantParams(): any {
      if (!this.INSPECTED_TENANT?.parameters?.content) {
        return { state: false, forced: false };
      }

      const parsedContent = JSON.parse(
        this.INSPECTED_TENANT?.parameters?.content
      );

      if ("CallValidDateTime_OnHoliday" in parsedContent === false) {
        return { state: false, forced: false };
      }

      return {
        state:
          parsedContent.CallValidDateTime_OnHoliday === "true" ||
          parsedContent.CallValidDateTime_OnHoliday === "True" ||
          parsedContent.CallValidDateTime_OnHoliday === true,
        forced: true,
      };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.CAMPAIGN_IMPORT_CONTACTS,
      Actions.TENANT_PARAMS_FETCH,
      Actions.CAMPAIGN_CONTACTS_FETCH,
    ]),
    ...mapActions(Modules.DATA_GRIDS, [Actions.CAMPAIGNS_DATAGRID_FETCH]),

    stepChange() {
      if (this.steps.activeStep === 3) {
        this.$router.push({
          name: Routes.CAMPAIGN_DETAIL,
          params: { idCampaign: this.campaignId },
        });
      } else {
        this.steps.activeStep = this.steps.activeStep + 1;
        this.$emit("stepChange", this.steps.activeStep);
      }
    },

    async handleCreateCampaign(): Promise<void> {
      const refs: any = this.$refs;
      const { name, callScriptCode, idLine, idTenant } = this.fields;

      await this.TENANT_PARAMS_FETCH({ idTenant });

      const { isFormSubmited, data } = await submitForm({
        actionId: Actions.CAMPAIGN_CREATE,
        ref: refs.step1,
        values: { name, callScriptCode, idLine, idTenant },
        url: ApiEndpoints.CAMPAIGN_CREATE,
      });

      if (isFormSubmited) {
        pushNotify(Notify.SUCCESS, "Campaign created successfully.");
        this.CAMPAIGNS_DATAGRID_FETCH();

        this.fields.publicHoliday = this.tenantParams?.state;
        this.campaignId = data?.idCampaign;
        this.steps.activeStep = this.steps.activeStep + 1;
        this.$emit("stepChange", this.steps.activeStep);

        const csvOutput = await this.CAMPAIGN_CONTACTS_FETCH(
          this.$route.params.idCampaign
        );
        const csvContactsFile: any = new File(
          [csvOutput],
          "Imported_contacts.csv",
          {
            type: "text/csv",
          }
        );
        this.filledCsv = csvOutput;

        this.fields.contactsFile = csvContactsFile;
      }
    },

    async handleCsvImport(): Promise<void> {
      const importData = await this.CAMPAIGN_IMPORT_CONTACTS({
        idCampaign: this.campaignId,
        contactsFile: this.fields.contactsFile,
        delimiter: this.fields.delimiter,
      });

      if (importData) {
        this.steps.activeStep = this.steps.activeStep + 1;
        this.$emit("stepChange", this.steps.activeStep);
      }
    },

    async handleScheduleSave(): Promise<void> {
      const refs: any = this.$refs;
      const {
        dow,
        runAsap,
        numberOfCallAttemps,
        retryCallAfterMinutes,
        ongoing,
        start,
        end,
        callFromDayTime,
        callToDayTime,
        publicHoliday,
      } = this.fields;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.CAMPAIGN_SCHEDULE_EDIT,
        ref: refs.step3,
        params: { idCampaign: this.campaignId },
        values: {
          dow,
          runAsap,
          numberOfCallAttemps,
          retryCallAfterSeconds: parseInt(retryCallAfterMinutes) * 60,
          ongoing,
          start: start ? formatTimezoneDateToUTC(start) : null,
          end: end ? formatTimezoneDateToUTC(end) : null,
          callFromDayTime,
          callToDayTime,
          publicHoliday,
        },
        url: ApiEndpoints.CAMPAIGN_SCHEDULE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.CAMPAIGN_CREATE, {});
        pushNotify(Notify.SUCCESS, "Campaign saved succesfully.");

        this.$router.push({
          name: Routes.CAMPAIGN_DETAIL,
          params: { idCampaign: this.campaignId },
        });
      }
    },

    submitStep(): void {
      switch (this.steps.activeStep) {
        case 1: {
          this.handleCreateCampaign();
          break;
        }
        case 2: {
          this.handleCsvImport();
          break;
        }
        case 3: {
          this.handleScheduleSave();
          break;
        }
        default:
          return;
      }
    },

    initPrefilledValues(): void {
      Object.keys(this.fields).map((field) => {
        this.fields[field] = this.INSPECTED_CAMPAIGN.basic[field];
      });

      this.fields.name = "";

      this.fields.retryCallAfterMinutes = (
        this.INSPECTED_CAMPAIGN.basic.retryCallAfterSeconds / 60
      ).toString();

      this.fields.dow = this.INSPECTED_CAMPAIGN.basic.callValidDays?.map(
        (day: string) => parseInt(day)
      );
    },
  },

  async created() {
    this.fields.idTenant = this.USER_INFO?.idTenant;

    if (this.prefilled && this.INSPECTED_CAMPAIGN?.basic) {
      this.initPrefilledValues();
    }
  },

  render(): VNode {
    return (
      <VStepper
        vModel={this.steps.activeStep}
        flat
        onChange={(step: boolean) => this.$emit("stepChange", step)}
      >
        <VStepperHeader class="mb-2">
          <VStepperStep step={1}>General</VStepperStep>
          <VDivider />
          <VStepperStep step={2}>Contacts</VStepperStep>
          <VDivider />
          <VStepperStep step={3}>Schedule</VStepperStep>
        </VStepperHeader>
        <VStepperItems>
          <VStepperContent step={1}>
            <VForm ref="step1" vModel={this.steps.isStep1Valid}>
              <VTextField
                vModel={this.fields.name}
                label="Name"
                rules={[isRequired]}
              />
              <FlowSelect
                vModel={this.fields.callScriptCode}
                options={{ rules: [isRequired] }}
              />
              <LineSelect
                vModel={this.fields.idLine}
                options={{ rules: [isRequired] }}
              />
              {hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
                Permissions.VIEW_ACCOUNT,
              ]) && (
                <TenantSelect
                  vModel={this.fields.idTenant}
                  permission={[Namespaces.CAMPAIGN, Permissions.EDIT]}
                />
              )}
            </VForm>
          </VStepperContent>
          <VStepperContent step={2}>
            <VForm ref="step2" vModel={this.steps.isStep1Valid}>
              <VFileInput
                label="Browse file (.csv, .xlsx, .txt)"
                vModel={this.fields.contactsFile}
                appendOuterIcon="mdi-download"
                on={{
                  "click:append-outer": () => {
                    const file: any = this.filledCsv;
                    downloadFile(file, "Imported_contacts", "csv");
                  },
                }}
                accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
              />
              <VAutocomplete
                class="ml-2"
                vModel={this.fields.delimiter}
                items={[",", ";"]}
                label="Delimiter used inside the file:"
              />
            </VForm>
          </VStepperContent>
          <VStepperContent step={3}>
            <VForm ref="step3" vModel={this.steps.isStep1Valid}>
              <VSelect
                label="Days of week"
                vModel={this.fields.dow}
                multiple
                smallChips
                deletableChips
                items={DAYS_IN_WEEK}
                rules={[isRequired]}
              />
              <VRow>
                <VCol md={6}>
                  <VTextField
                    vModel={this.fields.callFromDayTime}
                    label="Day start time"
                    rules={[isRequired, isTime]}
                  />
                </VCol>
                <VCol md={6}>
                  <VTextField
                    vModel={this.fields.callToDayTime}
                    label="Day end time"
                    rules={[isRequired, isTime]}
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol md={6}>
                  <VSwitch
                    vModel={this.fields.runAsap}
                    label="Run ASAP after processing"
                    class="d-inline-block mt-6 mb-2"
                    hideDetails
                  />
                </VCol>
                <VCol md={6}>
                  <VSwitch
                    vModel={this.fields.ongoing}
                    label="Ongoing Campaign"
                    class="d-inline-block mt-6 mb-2"
                    hideDetails
                  />
                </VCol>
                <VCol md={5}>
                  <VSwitch
                    vModel={this.fields.publicHoliday}
                    label="Call on public holiday"
                    class="d-inline-block mb-6"
                    hideDetails
                  />
                </VCol>
                <VCol md={7}>
                  {this.tenantParams.forced &&
                    !this.tenantParams.state &&
                    this.fields.publicHoliday && (
                      <VAlert
                        type="warning"
                        dense
                        border="left"
                        icon="mdi-alert"
                      >
                        Public holiday calls are disabled in tenant settings.
                        This option will be ignored unless it is allowed in
                        tenant configuration.
                      </VAlert>
                    )}
                </VCol>
              </VRow>
              <VRow>
                {!this.fields.runAsap && (
                  <VCol md={6}>
                    <VMenu
                      vModel={this.menus.campaignStart}
                      scopedSlots={{
                        activator: ({ on, attrs }: never) => (
                          <DateTimePicker
                            vModel={this.fields.start}
                            label="Campaign starts at"
                            firstDayOfWeek={1}
                            {...{ on, attrs }}
                          />
                        ),
                      }}
                    >
                      <DateTimePicker
                        vModel={this.fields.start}
                        firstDayOfWeek={1}
                      />
                    </VMenu>
                  </VCol>
                )}
                <VCol md={6}>
                  <VMenu
                    vModel={this.menus.campaignEnd}
                    scopedSlots={{
                      activator: ({ on, attrs }: never) => (
                        <DateTimePicker
                          vModel={this.fields.end}
                          label="Campaign ends at"
                          firstDayOfWeek={1}
                          {...{ on, attrs }}
                        />
                      ),
                    }}
                  ></VMenu>
                </VCol>
              </VRow>
              <VRow>
                <VCol md={6}>
                  <VTextField
                    vModel={this.fields.numberOfCallAttemps}
                    label="Number of call attempts"
                    rules={[isRequired, (val: string) => minValue(val, 1)]}
                    type="number"
                  />
                </VCol>
                <VCol md={6}>
                  {parseInt(this.fields.numberOfCallAttemps) !== 1 && (
                    <VTextField
                      vModel={this.fields.retryCallAfterMinutes}
                      label="Retry call after (min)"
                      rules={[isRequired, (val: string) => minValue(val, 1)]}
                      type="number"
                    />
                  )}
                </VCol>
              </VRow>
            </VForm>
          </VStepperContent>
        </VStepperItems>
      </VStepper>
    );
  },
});

export default CampaignCreateForm;
