import Vue, { VNode } from "vue";
import CountryFlag from "vue-country-flag";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import LanguageCreateDialog from "../LanguageCreateDialog";
import getCountryCode from "@/helpers/getCountryCode";
import useLanguageActions from "@/hooks/useLanguageActions";
import LanguageRemoveDialog from "../LanguageRemoveDialog";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const LanguageDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.LANGUAGES_DATAGRID_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Languages",
      isHeaderOutside: true,
      buttons: [
        {
          role: "primary",
          title: "New language",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.LANGUAGE, [
            Permissions.EDIT,
          ]),
          action: () => toggleDialog(Dialogs.LANGUAGE_CREATE, {}),
        },
      ],
      rowActions: (row) => ({
        primary: {
          route: {
            name: Routes.LANGUAGE_DETAIL_BASIC,
            params: { idLanguage: row.id },
          },
          icon: "mdi-cog",
          title: "Settings",
        },
        secondary: useLanguageActions({ idLanguage: row.id, name: row.name }),
      }),
      columnModificators: {
        "item.name": ({ item }) => (
          <div class="d-flex align-center">
            <CountryFlag size="small" country={getCountryCode(item.language)} />
            <span class="pl-1">{item.name}</span>
          </div>
        ),
      },
      filters: [{ name: "name" }, { name: "language" }],
      headers: [
        { text: "Name", value: "name" },
        { text: "Identificator", value: "language" },
      ],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.LANGUAGES_DATAGRID_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.LANGUAGES)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.LANGUAGES_DATAGRID_FETCH)}
        />
        <LanguageCreateDialog />
        <LanguageRemoveDialog />
      </div>
    );
  },
});

export default LanguageDataGrid;
