import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import GrammarActionsParams from "@/models/actions/GrammarActionsParams";

const { GRAMMAR } = Namespaces;

const useGrammarActions = ({
  id,
  name,
  idTenant,
}: GrammarActionsParams): ActionHandler[] => [
  {
    icon: "mdi-content-copy",
    title: "Duplicate",
    action: () => toggleDialog(Dialogs.GRAMMAR_DUPLICATE, { id, name }),
    isAllowed: hasUserPermissions(GRAMMAR, [Permissions.EDIT], idTenant),
  },
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.GRAMMAR_REMOVE, { id, name }),
    isAllowed: hasUserPermissions(GRAMMAR, [Permissions.DELETE], idTenant),
  },
];

export default useGrammarActions;
