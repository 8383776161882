import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";

import GrammarTypeSelect from "../GrammarTypeSelect";
import LanguageSelect from "../LanguageSelect";
import TenantSelect from "../TenantSelect";

const GrammarCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      language: "cs",
      idTenant: null,
      type: 1,
    },
    isFormValid: false,
    isUniqueName: true,
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.GRAMMAR_CHECK_UNIQUE_NAME]),

    async handleSubmit() {
      await this.checkUniqueName(this.fields.name, this.fields.language);

      if (!this.isUniqueName) {
        return;
      }

      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.GRAMMAR_CREATE,
        values: this.fields,
        actionId: Actions.GRAMMAR_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.GRAMMAR_CREATE, {});

        this.$router.push({
          name: Routes.GRAMMARS_DETAIL_BASIC,
          params: { idGrammar: data.id },
        });

        pushNotify(Notify.SUCCESS, "Grammar created successfully");
      }
    },

    async checkUniqueName(name: string, language: string) {
      const isUnique = await this.GRAMMAR_CHECK_UNIQUE_NAME({ name, language });

      if (isUnique === null) {
        return;
      }

      this.isUniqueName = isUnique;
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.name}
          rules={[isRequired]}
          label="Name"
          onBlur={(e: any) =>
            this.checkUniqueName(e.target.value, this.fields.language)
          }
          onInput={() => (this.isUniqueName = true)}
          errorMessages={
            !this.isUniqueName
              ? [`Name ${this.fields.name} is already used`]
              : null
          }
          loading={this.IS_ACTION_FETCHING(Actions.GRAMMAR_CHECK_UNIQUE_NAME)}
        />
        <GrammarTypeSelect vModel={this.fields.type} />
        <LanguageSelect
          vModel={this.fields.language}
          onInput={() =>
            this.checkUniqueName(this.fields.name, this.fields.language)
          }
        />
        <TenantSelect
          vModel={this.fields.idTenant}
          permission={[Namespaces.GRAMMAR, Permissions.EDIT]}
        />
      </VForm>
    );
  },
});

export default GrammarCreateForm;
