import Vue, { VNode } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { VChip } from "vuetify/lib";
import DataGrid from "../DataGrid";
import { Getters, Actions, Modules, Mutations } from "@/models/store";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import DataGrids from "@/constants/dataGrids";
import Routes from "@/constants/routes";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import useModuleActions from "@/hooks/useModuleActions";
import CsvExportDialog from "../CsvExportDialog";
import Dialogs from "@/constants/dialogs";
import ApiEndpoints from "@/constants/apiEndpoints";
import ModuleRemoveDialog from "../ModuleRemoveDialog";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import toggleDialog from "@/helpers/toggleDialog";
import ModuleCreateDialog from "../ModuleCreateDialog";

const ModulesDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),
  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },
  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.MODULES_DATAGRID_FETCH]),
    ...mapMutations(Modules.DIALOGS, [Mutations.TOGGLE_DIALOG]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Modules",
      isHeaderOutside: true,
      buttons: [
        {
          action: () => toggleDialog(Dialogs.MODULE_CREATE, {}),
          title: "New module",
          role: "primary",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.MODULE, [Permissions.EDIT]),
        },
      ],
      rowActions: (item) => ({
        primary: {
          icon: "mdi-eye",
          title: "Show detail",
          isAllowed: hasUserPermissions(
            Namespaces.MODULE,
            [Permissions.VIEW],
            item.idTenant
          ),
          route: {
            name: Routes.MODULE_DETAIL_BASIC,
            params: { idDialModule: item.id, title: item.name },
          },
        },
        secondary: useModuleActions(item),
      }),
      columnModificators: {
        "item.enable": ({ item }) => (
          <VChip color={item.enable ? "success" : "error"}>
            {item.enable ? "Enabled" : "Disabled"}
          </VChip>
        ),
        "item.lastActivity": ({ item }) => formatDate(item.lastActivity),
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Number", value: "phone" },
        { text: "Status", value: "enable" },
        { text: "Mode", value: "mode" },
        { text: "Last activity at", value: "lastActivity" },
      ],
      filters: [{ name: "name" }, { name: "phone" }],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.MODULES_DATAGRID_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.DIAL_MODULES)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.MODULES_DATAGRID_FETCH)}
        />

        <CsvExportDialog
          name={Dialogs.MODULE_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_REPORTS_MODULE}
          prefix="module"
        />
        <ModuleCreateDialog />
        <ModuleRemoveDialog />
      </div>
    );
  },
});

export default ModulesDataGrid;
