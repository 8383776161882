import Vue, { VNode } from "vue";
import { VForm, VSwitch, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import { Namespaces, Permissions } from "@/constants/permissions";
import getSectionEditableValues from "@/helpers/getSectionEditableValues";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Tag from "@/models/Tag";

import TagsSelect from "../TagsSelect";
import TenantSelect from "../TenantSelect";

const Fields = {
  name: "name",
  idTenant: "idTenant",
  tags: "tags",
  isPublic: "isPublic",
};

const FlowOverviewEditForm = Vue.extend({
  name: "FlowOverviewEditForm",
  data: () => ({
    fields: {},
    isValid: true,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),

    isComponent(): boolean {
      return this.INSPECTED_FLOW?.basic?.content?.isComponent;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_OVERVIEW_FETCH]),

    isEditable(name: string): boolean {
      return this.INSPECTED_FLOW.basic.editableFields.includes(name);
    },

    removeTag(name: string): void {
      const removedTagIndex = this.fields[Fields.tags].findIndex(
        (tag: Tag) => tag.title === name
      );
      const modifiedTags = JSON.parse(JSON.stringify(this.fields[Fields.tags]));
      modifiedTags.splice(removedTagIndex, 1);

      this.fields[Fields.tags] = [...modifiedTags];
    },

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      const submitedFields: any = getSectionEditableValues(
        this.fields,
        this.INSPECTED_FLOW.basic
      );
      const idTags = this.fields[Fields.tags]?.map((tag: any) => tag.idTag);
      const { tags, ...fields } = submitedFields;

      const { isFormSubmited } = await submitForm({
        actionId: Actions.FLOW_OVERVIEW_EDIT,
        ref: refs.form,
        values: {
          ...fields,
          idTags,
        },
        url: ApiEndpoints.FLOW_EDIT_BASIC,
        params: { idCallScript: this.$route.params.idCallScript },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.FLOW_EDIT_BASIC, {});
        pushNotify(
          Notify.SUCCESS,
          `${this.isComponent ? "Component" : "Flow"} edited successfully`
        );

        this.FLOW_OVERVIEW_FETCH({
          idCallScript: this.$route.params.idCallScript,
        });
      }
    },
  },

  created() {
    this.fields = { ...this.INSPECTED_FLOW.basic.content };
  },

  render(): VNode {
    const fields: any = this.fields;

    return (
      <VForm vModel={this.isValid} ref="form">
        <VTextField
          vModel={fields[Fields.name]}
          label="Name"
          disabled={!this.isEditable(Fields.name)}
          rules={[isRequired]}
        />
        <TenantSelect
          vModel={fields[Fields.idTenant]}
          disabled={!this.isEditable(Fields.idTenant)}
          options={{ clearable: true }}
          permission={[Namespaces.CALLSCRIPT, Permissions.EDIT]}
        />
        {this.isComponent ? (
          <VSwitch
            vModel={fields[Fields.isPublic]}
            label="Public access"
            disabled={!this.isEditable(Fields.isPublic)}
            class="d-inline-block"
          />
        ) : (
          <TagsSelect
            vModel={fields[Fields.tags]}
            options={{ disabled: !this.isEditable(Fields.tags) }}
            onTagCreated={(tag: Tag) =>
              (fields[Fields.tags] = [...fields[Fields.tags], tag])
            }
            onSelectionClose={(item: any) => this.removeTag(item.text)}
          />
        )}
      </VForm>
    );
  },
});

export default FlowOverviewEditForm;
