import Vue, { VNode } from "vue";
import CountryFlag from "vue-country-flag";
import { VChip, VIcon } from "vuetify/lib";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import Routes from "@/constants/routes";
import useLineActions from "@/hooks/useLineActions";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import ApiEndpoints from "@/constants/apiEndpoints";
import ChangeModuleDialog from "@/components/ChangeModuleDialog";
import LineCreateDialog from "../LineCreateDialog";
import LineEnableDialog from "@/components/LineEnableDialog";
import LineRemoveDialog from "@/components/LineRemoveDialog";
import CsvExportDialog from "../CsvExportDialog";
import SetDefaultLineDialog from "../SetDefaultLineDialog";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import getCountryCode from "@/helpers/getCountryCode";

const LinesDataGrid = Vue.extend({
  name: "LinesDataGrid",
  props: {
    isInsideDetail: Boolean,
  },

  data: () => ({
    dataGridConfig: {},
    idDialModule: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },
  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.LINES_DATAGRID_FETCH]),

    withModule(): any {
      return !this.$route.params.idDialModule
        ? [{ text: "Module", value: "dialModule" }]
        : [];
    },
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: `${this.isInsideDetail ? "Module lines" : "Lines"}`,
      disableElevation: this.isInsideDetail,
      isHeaderOutside: !this.isInsideDetail,
      buttons: [
        {
          action: () => toggleDialog(Dialogs.LINE_CREATE, {}),
          title: "New line",
          role: "primary",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.LINE, [Permissions.EDIT]),
        },
      ],
      rowActions: (items) => ({
        primary: {
          route: {
            name: Routes.LINE_DETAIL_BASIC,
            params: { idDialModuleLine: items.id },
          },
          icon: "mdi-eye",
          title: "Show detail",
          isAllowed: hasUserPermissions(
            Namespaces.LINE,
            [Permissions.VIEW],
            items.idTenant
          ),
        },
        secondary: [
          ...(this.$route.params.idDialModule
            ? [
                {
                  icon: "mdi-check",
                  title: "Set as default",
                  isAllowed: hasUserPermissions(
                    Namespaces.LINE,
                    [Permissions.EDIT],
                    items.idTenant
                  ),
                  action: () =>
                    toggleDialog(Dialogs.MODULE_SET_DEFAULT_LINE, items),
                },
              ]
            : []),
          ...useLineActions(items),
        ],
      }),
      columnModificators: {
        "item.name": ({ item }) => (
          <div class="d-flex align-center">
            <div class="mr-1">{item.name}</div>
            {item.languageCode && (
              <CountryFlag
                size="small"
                country={getCountryCode(item.languageCode)}
              />
            )}
          </div>
        ),
        "item.enable": ({ item }) => (
          <VChip color={item.enable ? "success" : "error"}>
            {item.enable ? "Enabled" : "Disabled"}
          </VChip>
        ),
        "item.isDefault": ({ item }) =>
          item.isDefault ? (
            <VIcon color="success">mdi-checkbox-marked-circle</VIcon>
          ) : (
            <VIcon color="error">mdi-close-circle</VIcon>
          ),
        "item.callScript": ({ item }) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.FLOW_DETAIL_BASIC,
              params: { idCallScript: item.idCallScript },
            }}
          >
            {item.callScript}
          </router-link>
        ),
        "item.dialModule": ({ item }) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.MODULE_DETAIL_BASIC,
              params: { idDialModule: item.idDialModule },
            }}
          >
            {item.dialModule}
          </router-link>
        ),
      },
      headers: [
        { text: "Name", value: "name" },
        ...this.withModule(),
        { text: "Status", value: "enable" },
        { text: "Identificator", value: "identificator" },
        { text: "Call script", value: "callScript" },
        { text: "Default", value: "isDefault" },
      ],
      filters: [
        { name: "name" },
        { name: "callScript" },
        { name: "identificator" },
      ],
    };

    this.dataGridConfig = dataGridConfig;
    this.idDialModule = this.$route.params.idDialModule;
  },

  render(): VNode {
    const moduleId = this.$route.params.idDialModule;

    return (
      <div>
        <DataGrid
          fetchData={this.LINES_DATAGRID_FETCH}
          fetchProps={{ ...(moduleId && { idDialModule: moduleId }) }}
          data={this.GET_DATAGRID_DATA(DataGrids.LINES)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.LINES_DATAGRID_FETCH)}
        />

        <CsvExportDialog
          name={Dialogs.LINE_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_REPORTS_LINE}
          prefix="line"
        />
        <ChangeModuleDialog />
        <LineCreateDialog idDialModule={this.idDialModule ?? null} />
        <LineEnableDialog />
        <LineRemoveDialog />
        <SetDefaultLineDialog />
      </div>
    );
  },
});

export default LinesDataGrid;
