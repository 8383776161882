import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapActions } from "vuex";

import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { hasNoSpecialChars, isRequired } from "@/helpers/validations";
import { Actions, Modules } from "@/models/store";

import NlpDatasetsSelect from "../NlpDatasetsSelect";
import NlpTextClassifierSelect from "../NlpTextClassifierSelect";

const NlpTesterModelsCreateForm = Vue.extend({
  data: () => ({
    fields: {
      dataset: "" as any,
      modelId: "",
      textClassifier: "MLNet",
    },
    isFormValid: true,
  }),

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.NLP_TESTER_MODELS_FETCH]),

    async handleSubmit() {
      const { dataset, ...values } = this.fields;

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: `${ApiEndpoints.NLP_TESTER_MODELS}/${this.fields.dataset?.language}`,
        values: { datasetId: dataset?.datasetId?.trim(), ...values },
        actionId: Actions.NLP_TESTER_MODEL_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.NLP_TESTER_MODEL_CREATE, {});
        this.NLP_TESTER_MODELS_FETCH();

        pushNotify(Notify.SUCCESS, "Model created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <NlpDatasetsSelect
          vModel={this.fields.dataset}
          options={{ rules: [isRequired] }}
        />
        <VTextField
          vModel={this.fields.modelId}
          label="Name"
          rules={[isRequired, hasNoSpecialChars]}
        />
        <NlpTextClassifierSelect vModel={this.fields.textClassifier} />
      </VForm>
    );
  },
});

export default NlpTesterModelsCreateForm;
