import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VSelect, VTextField } from "vuetify/lib";
import TenantSelect from "../TenantSelect";
import { Namespaces, Permissions } from "@/constants/permissions";

const ModuleCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      mode: "SIPClient",
      idTenant: null,
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;

      const { isFormSubmited, data } = await submitForm({
        actionId: Actions.MODULE_CREATE,
        ref: refs.form,
        values: this.fields,
        url: ApiEndpoints.MODULE_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.MODULE_CREATE, {});

        this.$router.push({
          name: Routes.MODULE_DETAIL_BASIC,
          params: { idDialModule: data.id },
        });

        pushNotify(Notify.SUCCESS, "Module created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm ref="form" vModel={this.isFormValid}>
        <VTextField
          vModel={this.fields.name}
          label="Name"
          rules={[isRequired]}
        />
        <VSelect vModel={this.fields.mode} items={["SIPClient"]} label="Mode" />
        <TenantSelect
          vModel={this.fields.idTenant}
          options={{ clearable: true }}
          permission={[Namespaces.MODULE, Permissions.EDIT]}
        />
      </VForm>
    );
  },
});

export default ModuleCreateForm;
