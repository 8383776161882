const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TIME_PATTERN = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const isRequired = (val: string): string | boolean =>
  val?.toString().replace(/\s/g, "") !== "" || "Field is required";

export const isEqualTo = (val: any, expectedVal: any): string | boolean =>
  val === expectedVal || "Value not match";

export const isEmail = (val: string): string | boolean =>
  EMAIL_PATTERN.test(val.toLowerCase()) || "Invalid email format";

export const isTime = (val: string): string | boolean =>
  TIME_PATTERN.test(val) || "Time must be in 'XX:YY' format";

export const hasMinLength = (val: string, length: number): string | boolean =>
  val.length >= length || `Field must contains at least ${length} characters`;

export const hasExactLength = (val: string, length: number): string | boolean =>
  val.length === length || `Field must contains exactly ${length} characters`;

export const hasNoSpecialChars = (val: string): string | boolean =>
  /^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$/.test(val) ||
  "Field contains illegal characters";

export const containsSmallLettersOnly = (val: string): string | boolean =>
  /^[a-z]*$/.test(val) || "Field must contains only small letters (a - z)";

export const isInteger = (val: string): string | boolean =>
  /^\d+$/.test(val) || "Field must contains only whole numbers";

export const isNumberInRange = (
  val: string,
  range: number[]
): string | boolean =>
  (Number(val) >= range[0] && Number(val) <= range[1]) ||
  `Number must be in range ${range[0]} - ${range[1]}`;

export const minValue = (val: string, minVal: number): string | boolean =>
  parseInt(val) >= minVal || `Minimum value is ${minVal}`;
