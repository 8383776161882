import Vue, { VNode } from "vue";
import { VBtn, VChip, VIcon } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import { FLOW_TYPE_ICONS } from "@/constants/valueIcons";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import { getTagColorClass } from "@/helpers/tagColorConfig";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Tag from "@/models/Tag";

import Dialog from "../Dialog";
import EditableOverviewSection from "../EditableOverviewSection";
import FlowOverviewEditForm from "../FlowOverviewEditForm";

const FlowOverview = Vue.extend({
  name: "FlowOverview",
  data: () => ({
    basic: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    isComponent(): boolean {
      return this.INSPECTED_FLOW?.basic?.content?.isComponent;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.FLOW_OVERVIEW_FETCH]),

    getPublishLink(type: number, title: string, id: number): VNode {
      switch (type) {
        case 0:
          return (
            <router-link
              class="tertiary--text"
              to={{
                name: Routes.CAMPAIGN_DETAIL,
                params: { idCampaign: String(id) },
              }}
            >
              {title}
            </router-link>
          );
        default:
          return (
            <router-link
              class="tertiary--text"
              to={{
                name: Routes.LINE_DETAIL_BASIC,
                params: { idDialModuleLine: id },
              }}
            >
              {title}
            </router-link>
          );
      }
    },
  },

  created() {
    this.basic = {
      title: "Overview",
      modificators: {
        created: ({ created }: any) => formatDate(created),
        changed: ({ changed }: any) => formatDate(changed),
        type: ({ type }: any) => FLOW_TYPE_ICONS[type].name,
        published: ({ published }: any) =>
          published?.length === 0 ? (
            "-"
          ) : (
            <div>
              {[...published.slice(0, 4)].map(({ type, title, id }: any) => (
                <div>{this.getPublishLink(type, title, id)}</div>
              ))}
              {published.length > 4 && (
                <div>
                  <div>...</div>
                  <VBtn
                    onClick={() =>
                      toggleDialog(Dialogs.FLOW_PUBLISHED_LINES, {})
                    }
                    class="tertiary white--text"
                    xSmall
                  >
                    <VIcon xSmall left>
                      mdi-open-in-new
                    </VIcon>
                    Show all ({published.length})
                  </VBtn>
                </div>
              )}
            </div>
          ),

        tags: ({ tags }: any) =>
          tags?.length === 0
            ? "-"
            : tags?.map(({ title, color }: Tag) => {
                return (
                  <VChip class="mr-2 mb-2" color={getTagColorClass(color)}>
                    {title}
                  </VChip>
                );
              }),
        isPublic: ({ isPublic }: any) =>
          isPublic ? (
            <VIcon color="success">mdi-checkbox-marked-circle</VIcon>
          ) : (
            <VIcon color="error">mdi-close-circle</VIcon>
          ),
      },
    };
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={{
            ...this.basic,
            columns: [
              { name: "name", title: "Name" },
              { name: "author", title: "Author" },
              { name: "created", title: "Created at" },
              { name: "changed", title: "Last changed at" },
              { name: "tenant", title: "Tenant" },
              { name: "type", title: "Type" },
              ...(!this.isComponent
                ? [{ name: "published", title: "Published at" }]
                : []),
              { name: "guid", title: "Guid" },
              ...(!this.isComponent ? [{ name: "tags", title: "Tags" }] : []),
              ...(this.isComponent
                ? [{ name: "isPublic", title: "Public access" }]
                : []),
            ],
          }}
          data={this.INSPECTED_FLOW?.basic}
          editDialog={Dialogs.FLOW_EDIT_BASIC}
          action={Actions.FLOW_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <FlowOverviewEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(Actions.FLOW_OVERVIEW_FETCH)}
        />
        <Dialog id={Dialogs.FLOW_PUBLISHED_LINES} title="Published at">
          {this.INSPECTED_FLOW?.basic?.content?.published?.map(
            ({ type, id, title }: any) => (
              <div>{this.getPublishLink(type, title, id)}</div>
            )
          )}
        </Dialog>
      </div>
    );
  },
});

export default FlowOverview;
