import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VBtn, VCol, VDivider, VIcon, VRow, VSelect } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import Dialog from "../Dialog";
import TenantSelect from "../TenantSelect";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const UserRolesDialog = Vue.extend({
  data: () => ({
    roles: [],
    fields: {},
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),

    userTenantId(): any {
      return this.INSPECTED_USER?.basic?.content?.idTenant;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_ROLES_FETCH]),

    initRoles(): void {
      this.roles = this.INSPECTED_USER?.roles;

      this.INSPECTED_USER?.roles.map(({ hasRole, name, idTenants }: any) => {
        if (idTenants?.[0] === -1) {
          this.fields[name] = "*";
          return;
        }

        if (idTenants?.length > 0) {
          this.fields[name] = true;
          this.fields[`${name}-tenants`] = idTenants;

          return;
        }

        this.fields[name] = hasRole;
      });
    },

    async handleSubmit(): Promise<void> {
      const enabledRoles: any[] = [];

      Object.keys(this.fields).map((role) => {
        if (this.fields[role] === false) {
          return;
        }

        const roleConfig = this.INSPECTED_USER?.roles.find(
          ({ name }: any) => name === role
        );

        if (this.fields[role] === true) {
          enabledRoles.push({
            role: roleConfig?.id,
            idTenants: this.fields[`${role}-tenants`],
          });
        }

        if (this.fields[role] === "*") {
          enabledRoles.push({ role: roleConfig?.id, idTenants: [-1] });
        }
      });

      const { isFormSubmited } = await submitForm({
        values: { roles: enabledRoles },
        url: ApiEndpoints.TENANT_USER_ROLES,
        params: { idUser: this.$route.params.idUser },
        actionId: Actions.USER_ROLES_FETCH,
      });

      if (isFormSubmited) {
        pushNotify(Notify.SUCCESS, "User roles changed successfully");
        toggleDialog(Dialogs.USER_ROLES);
        this.USER_ROLES_FETCH({ idUser: this.$route.params.idUser });
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.USER_ROLES}
        title="Edit user roles"
        width={700}
        actions={[
          {
            title: "Save",
            icon: "mdi-content-save",
            id: Actions.USER_ROLES_FETCH,
            action: () => this.handleSubmit(),
          },
        ]}
        toggleHandler={(isActive: boolean) =>
          isActive ? this.initRoles() : null
        }
      >
        {this.roles?.map(({ name }, index) => (
          <div>
            <VRow class="align-baseline">
              <VCol md={5} class="font-weight-bold primary--text">
                {name}
              </VCol>
              <VCol md={7}>
                <VSelect
                  items={[
                    { text: "Disabled", value: false },
                    ...(hasUserPermissions(
                      Namespaces.ACCESS_MANAGEMENT,
                      [Permissions.EDIT_ACCOUNT_PERMISSION],
                      -1
                    )
                      ? [{ text: "Enabled for all tenants", value: "*" }]
                      : []),
                    { text: "Enabled for specific tenants", value: true },
                  ]}
                  value={this.fields[name]}
                  onInput={(val: any) => {
                    this.fields = {
                      ...this.fields,
                      [name]: val,
                    };
                  }}
                  hideDetails
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol md={7} offset={5}>
                {this.fields[name] === true && (
                  <div>
                    <TenantSelect
                      label="Tenants"
                      options={{
                        multiple: true,
                        chips: true,
                        smallChips: true,
                        deletableChips: true,
                      }}
                      vModel={this.fields[`${name}-tenants`]}
                      permission={[
                        Namespaces.ACCESS_MANAGEMENT,
                        Permissions.EDIT_ROLE,
                      ]}
                    />
                    {!(this.fields[`${name}-tenants`] as any[])?.includes(
                      this.userTenantId
                    ) && (
                      <VBtn
                        text
                        xSmall
                        color="tertiary"
                        class="mb-2 mt-n1"
                        onClick={() => {
                          if (!this.fields[`${name}-tenants`]) {
                            this.$set(this.fields, `${name}-tenants`, []);
                          }

                          (this.fields[`${name}-tenants`] as any).push(
                            this.userTenantId
                          );
                        }}
                      >
                        <VIcon left>mdi-plus</VIcon>Add user tenant
                      </VBtn>
                    )}
                  </div>
                )}
              </VCol>
            </VRow>

            {index + 1 !== this.roles.length && <VDivider />}
          </div>
        ))}
      </Dialog>
    );
  },
});

export default UserRolesDialog;
