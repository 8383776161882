import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import useTenantActions from "@/hooks/useTenantActions";
import TenantRemoveDialog from "../TenantRemoveDialog";
import TenantForceTwoFactorDialog from "../TenantForceTwoFactorDialog";
import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";

const TenantDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),

    instanceData(): any {
      const id = this.$route.params.idTenant;

      return { basic: this.INSPECTED_TENANT?.basic?.content, id };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Tenant - ${this.instanceData?.basic?.invoicingName || ""}`}
          defaultReturnRoute={Routes.TENANTS_OVERVIEW}
          initDataFetch={() =>
            this.TENANT_OVERVIEW_FETCH({
              idTenant: this.instanceData.id,
            })
          }
          actions={[
            {
              icon: "mdi-shield-lock",
              title: "Enable two factor",
              action: () =>
                toggleDialog(
                  Dialogs.TENANT_FORCE_TWO_FACTOR,
                  this.instanceData
                ),
              isAllowed:
                hasUserPermissions(
                  Namespaces.ACCESS_MANAGEMENT,
                  [Permissions.EDIT_2FA],
                  parseInt(this.instanceData.id)
                ) && !this.instanceData?.basic?.twoFactorRequirement,
            },
            ...useTenantActions({
              id: parseInt(this.instanceData?.id),
              ...this.instanceData.basic,
            }),
          ]}
          tabs={[
            {
              title: "General",
              path: Routes.TENANT_DETAIL_BASIC,
            },
            {
              title: "Users",
              path: Routes.TENANT_DETAIL_USERS,
            },
            {
              title: "Statistics",
              path: Routes.TENANT_DETAIL_STATISTICS,
            },
            {
              title: "Notifications",
              path: Routes.TENANT_DETAIL_NOTIFICATIONS,
            },
          ]}
        />
        <TenantRemoveDialog />
        <TenantForceTwoFactorDialog />
      </div>
    );
  },
});

export default TenantDetail;
