import { isRequired, isTime, minValue } from "@/helpers/validations";
import { Actions, Getters, Modules, Mutations } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCol,
  VForm,
  VMenu,
  VRow,
  VSelect,
  VSwitch,
  VTextField,
} from "vuetify/lib";
import FlowSelect from "../FlowSelect";
import LineSelect from "../LineSelect";
import DAYS_IN_WEEK from "@/constants/daysInWeek";
import { mapActions, mapGetters, mapMutations } from "vuex";
import toggleDialog from "@/helpers/toggleDialog";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import TenantSelect from "../TenantSelect";
import Dialogs from "@/constants/dialogs";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import RequestState from "@/models/api/RequestState";
import DateTimePicker from "../DateTimePicker";
import {
  formatDate,
  formatDateForInput,
  formatTimezoneDateToUTC,
} from "@/helpers/dateAndTimeUtils";

const CampaignEditForm = Vue.extend({
  data: () => ({
    idCampaign: "",
    fields: {
      name: "",
      idTenant: "",
      callScriptCode: "",
      idLine: "",
      dow: [1, 2, 3, 4, 5],
      runAsap: true,
      ongoing: true,
      numberOfCallAttemps: "2",
      retryCallAfterSeconds: "3600",
      start: "",
      end: "",
      callFromDayTime: "9:00",
      callToDayTime: "18:00",
      publicHoliday: false,
    },
    menus: {
      campaignStart: false,
      campaignEnd: false,
    },
    isFormValid: false,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_CAMPAIGN]),
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.CAMPAIGN_IMPORT_CONTACTS,
      Actions.CAMPAIGN_SCHEDULE_EDIT,
      Actions.CAMPAIGN_BASIC_EDIT,
      Actions.CAMPAIGN_OVERVIEW_FETCH,
    ]),
    ...mapMutations(Modules.API_CALLS, [Mutations.SET_API_CALL_STATE]),

    async handleSubmit(): Promise<void> {
      const refs: any = this.$refs;
      refs.form?.validate();

      if (this.isFormValid) {
        const {
          name,
          idTenant,
          callScriptCode,
          idLine,
          start,
          end,
          ...scheduleData
        } = this.fields;

        this.SET_API_CALL_STATE({
          action: Actions.CAMPAIGN_EDIT,
          actionState: RequestState.FETCHING,
          id: 0,
        });

        const submitedData: any[] = await Promise.all([
          this.CAMPAIGN_BASIC_EDIT({
            idCampaign: this.idCampaign,
            data: { name, idTenant, callScriptCode, idLine },
          }),

          this.CAMPAIGN_SCHEDULE_EDIT({
            idCampaign: this.idCampaign,
            data: {
              ...scheduleData,
              start: start ? formatTimezoneDateToUTC(start) : null,
              end: end ? formatTimezoneDateToUTC(end) : null,
            },
          }),
        ]);

        if (submitedData.includes(false)) {
          this.SET_API_CALL_STATE({
            action: Actions.CAMPAIGN_EDIT,
            actionState: RequestState.ERROR,
            id: 0,
          });

          return;
        }

        this.SET_API_CALL_STATE({
          action: Actions.CAMPAIGN_EDIT,
          actionState: RequestState.SUCCESS,
          id: 0,
        });

        toggleDialog(Dialogs.CAMPAIGN_EDIT, {});
        pushNotify(Notify.SUCCESS, "Campaign edited successfully");
        this.CAMPAIGN_OVERVIEW_FETCH({ idCampaign: this.idCampaign });
      }
    },
  },

  created() {
    this.fields = { ...this.fields, ...this.INSPECTED_CAMPAIGN?.basic };
    this.idCampaign = this.$route.params.idCampaign;
    this.fields.runAsap = this.fields.start === null ? true : false;
    this.fields.idTenant = this.INSPECTED_CAMPAIGN?.basic.idTenant;
    this.fields.publicHoliday = this.INSPECTED_CAMPAIGN?.basic.callOnHoliday;

    this.fields.start = this.fields.start
      ? formatDateForInput(this.fields.start)
      : "";
    this.fields.end = this.fields.end
      ? formatDateForInput(this.fields.end)
      : "";
  },

  render(): VNode {
    return (
      <VForm ref="form" vModel={this.isFormValid}>
        <VTextField
          vModel={this.fields.name}
          label="Name"
          rules={[isRequired]}
        />
        <FlowSelect
          vModel={this.fields.callScriptCode}
          options={{ rules: [isRequired] }}
        />
        <LineSelect
          vModel={this.fields.idLine}
          options={{ rules: [isRequired] }}
        />
        <VSelect
          label="Days of week"
          vModel={this.fields.dow}
          multiple
          smallChips
          deletableChips
          items={DAYS_IN_WEEK}
          rules={[isRequired]}
          class="mt-6"
        />
        <VRow>
          <VCol md={6}>
            <VTextField
              vModel={this.fields.callFromDayTime}
              label="Day start time"
              rules={[isRequired, isTime]}
            />
          </VCol>
          <VCol md={6}>
            <VTextField
              vModel={this.fields.callToDayTime}
              label="Day end time"
              rules={[isRequired, isTime]}
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol md={6}>
            <VSwitch
              vModel={this.fields.runAsap}
              label="Run ASAP after processing"
              class="d-inline-block mt-6 mb-2"
              hideDetails
            />
          </VCol>
          <VCol md={6}>
            <VSwitch
              vModel={this.fields.ongoing}
              label="Ongoing Campaign"
              class="d-inline-block mt-6 mb-2"
              hideDetails
            />
          </VCol>
          <VCol md={6}>
            <VSwitch
              vModel={this.fields.publicHoliday}
              label="Call on public holiday"
              class="d-inline-block mb-6"
              hideDetails
            />
          </VCol>
        </VRow>
        <VRow>
          {!this.fields.runAsap && (
            <VCol md={6}>
              <VMenu
                vModel={this.menus.campaignStart}
                scopedSlots={{
                  activator: ({ on, attrs }: never) => (
                    <DateTimePicker
                      vModel={this.fields.start}
                      label="Campaign starts at"
                      firstDayOfWeek={1}
                      {...{ on, attrs }}
                    />
                  ),
                }}
              >
                <DateTimePicker vModel={this.fields.start} firstDayOfWeek={1} />
              </VMenu>
            </VCol>
          )}
          <VCol md={6}>
            <VMenu
              vModel={this.menus.campaignEnd}
              scopedSlots={{
                activator: ({ on, attrs }: never) => (
                  <DateTimePicker
                    vModel={this.fields.end}
                    label="Campaign ends at"
                    firstDayOfWeek={1}
                    {...{ on, attrs }}
                  />
                ),
              }}
            ></VMenu>
          </VCol>
        </VRow>

        <VRow class="mt-6">
          <VCol md={6}>
            <VTextField
              vModel={this.fields.numberOfCallAttemps}
              label="Number of call attempts"
              rules={[isRequired, (val: string) => minValue(val, 1)]}
              type="number"
            />
          </VCol>
          <VCol md={6}>
            {parseInt(this.fields.numberOfCallAttemps) !== 1 && (
              <VTextField
                value={JSON.stringify(
                  parseInt(this.fields.retryCallAfterSeconds) / 60
                )}
                onChange={(val: string) =>
                  (this.fields.retryCallAfterSeconds = JSON.stringify(
                    parseInt(val) * 60
                  ))
                }
                label="Retry call after (min)"
                rules={[isRequired, (val: string) => minValue(val, 1)]}
                type="number"
              />
            )}
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            {hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
              Permissions.VIEW_ACCOUNT,
            ]) && (
              <TenantSelect
                vModel={this.fields.idTenant}
                permission={[Namespaces.CAMPAIGN, Permissions.EDIT]}
              />
            )}
          </VCol>
        </VRow>
      </VForm>
    );
  },
});

export default CampaignEditForm;
