import Vue, { VNode } from "vue";
import { VList, VListItem } from "vuetify/lib";

import Dialogs from "@/constants/dialogs";

import Dialog from "../Dialog";

const PackageManagerInstallResultDialog = Vue.extend({
  props: {
    resultData: Object,
  },

  render(): VNode {
    return (
      <Dialog title="Package install result" id={Dialogs.PACKAGE_INSTALL}>
        <h3 class="text-subtitle-1 font-weight-bold primary--text mt-3">
          Statistics:
        </h3>
        <VList dense class="pt-0">
          {this.resultData?.statistics && (
            <div>
              {Object.entries(this.resultData?.statistics).map(
                ([key, value]) => (
                  <VListItem style="min-height: 0">
                    <span class="mr-1">{key}:</span>{" "}
                    <span class="font-weight-bold primary--text">
                      {Array.isArray(value) ? value.join(",") : value}
                    </span>
                  </VListItem>
                )
              )}
            </div>
          )}
        </VList>

        {this.resultData?.errors &&
          Object.entries(this.resultData?.errors).length !== 0 && (
            <div>
              <h3 class="text-subtitle-1 font-weight-bold error--text mt-3">
                Errors:
              </h3>
              <VList dense class="pt-0">
                {Object.entries(this.resultData?.errors).map(([key, value]) => (
                  <VListItem style="min-height: 0">
                    <span class="mr-1">{key}:</span>{" "}
                    <span class="font-weight-bold primary--text">{value}</span>
                  </VListItem>
                ))}
              </VList>
            </div>
          )}
      </Dialog>
    );
  },
});

export default PackageManagerInstallResultDialog;
