import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import UserApiSecrets from "../UserApiSecrets";
import UserRolesOverview from "../UserRolesOverview";
import UserTwoFactorAuthentication from "../UserTwoFactorAuthentication";
import Dropdowns from "@/constants/dropdowns";
import ApiEndpoints from "@/constants/apiEndpoints";

const UserSecurity = Vue.extend({
  data: () => ({
    idUser: "",
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.USER, [Getters.USER_INFO, Getters.USER_ACCOUNTS]),

    enabledRoles(): any[] {
      const enabledRoles: any[] = [];
      this.INSPECTED_USER?.roles?.map((role: any) =>
        role.hasRole ? enabledRoles.push(role) : null
      );
      return enabledRoles;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_ROLES_FETCH]),
    ...mapActions(Modules.DROPDOWNS, [Actions.DROPDOWN_DATA_FETCH]),
  },

  created() {
    this.idUser = this.$route.params.idUser || this.USER_INFO?.id;
    this.idUser ? this.USER_ROLES_FETCH({ idUser: this.idUser }) : null;

    this.DROPDOWN_DATA_FETCH({
      dropdown: Dropdowns.TENANTS,
      url: ApiEndpoints.TENANT_LIST,
      isValueNumber: true,
    });
  },

  render(): VNode {
    return (
      <div>
        <UserRolesOverview data={this.enabledRoles} />
        <VDivider class="mb-3 mt-4" />
        <UserApiSecrets idUser={this.idUser} />
        <VDivider class="mb-3 mt-4" />
        <UserTwoFactorAuthentication
          idUser={this.idUser}
          isLoggedUser={!this.$route.params.idUser}
        />
      </div>
    );
  },
});

export default UserSecurity;
