import { RouteConfig } from "vue-router";
import Routes, { DEFAULT_TITLE } from "@/constants/routes";
import FlowsDataGrid from "@/components/FlowsDataGrid";
import CampaignsDataGrid from "@/components/CampaignsDataGrid";
import ModuleDetail from "@/components/ModuleDetail";
import ModulesDataGrid from "@/components/ModulesDataGrid";
import ModuleOverview from "@/components/ModuleOverview";
import LinesDataGrid from "@/components/LinesDataGrid";
import DialLogDataGrid from "@/components/DialLogDataGrid";
import AdministrationGuide from "@/components/AdministrationGuide";
import RouterNavigation from "@/components/RouterNavigation";
import LineOverview from "@/components/LineOverview";
import LineDetail from "@/components/LineDetail";
import FlowDetail from "@/components/FlowDetail";
import FlowOverview from "@/components/FlowOverview";
import FlowVersionHistoryDataGrid from "@/components/FlowVersionHistoryDataGrid";
import NotFound from "@/components/NotFound";
import GrammarsDataGrid from "@/components/GrammarsDataGrid";
import GrammarDetail from "@/components/GrammarDetail";
import GrammarOverview from "@/components/GrammarOverview";
import TtsGenerator from "@/components/TtsGenerator";
import PackageManager from "@/components/PackageManager";
import UsersDataGrid from "@/components/UsersDataGrid";
import TenantsDataGrid from "@/components/TenantsDataGrid";
import TenantDetail from "@/components/TenantDetail";
import TenantOverview from "@/components/TenantOverview";
import TenantNotifications from "@/components/TenantNotificationGroups";
import UserDetail from "@/components/UserDetail";
import UserOverview from "@/components/UserOverview";
import LineMonitoring from "@/components/LineMonitoring";
import AuditDataGrid from "@/components/AuditDataGrid";
import UserProfileDetail from "@/components/UserProfileDetail";
import NotAuthorized from "@/components/NotAuthorized";
import ComponentsDataGrid from "@/components/ComponentsDataGrid";
import { Namespaces, Permissions } from "@/constants/permissions";
import PermissionGroupsDataGrid from "@/components/PermissionGroupsDataGrid";
import PermissionGroupDetail from "@/components/PermissionGroupDetail";
import PermissionGroupOverview from "@/components/PermissionGroupOverview";
import UserSecurity from "@/components/UserSecurity";
import PackageManagerInstall from "@/components/PackageManagerInstall";
import PackageManagerCreate from "@/components/PackageManagerCreate";
import Reporting from "@/components/Reporting";
import TenantStatistics from "@/components/TenantStatistics";
import CampaignDashboard from "@/components/CampaignDashboard";
import LineStatistics from "@/components/LineStatistics";
import TenantStatisticsAll from "@/components/TenantStatisticsAll";
import DialLogDetail from "@/components/DialLogDetail/DialLogDetail";
import BotDeploy from "@/components/BotDeploy";
import BotDeployModulesDataGrid from "@/components/BotDeployModulesDataGrid";
import BotDeployModuleOverview from "@/components/BotDeployModuleOverview";
import BotDeployModuleDetail from "@/components/BotDeployModuleDetail";
import BotDeployServersDataGrid from "@/components/BotDeployServersDataGrid";
import BotDeployServerOverview from "@/components/BotDeployServerOverview";
import BotDeployServerDetail from "@/components/BotDeployServerDetail";
import BotDeployConfigsDataGrid from "@/components/BotDeployConfigsDataGrid";
import BotDeployConfigOverview from "@/components/BotDeployConfigOverview";
import BotDeployConfigDetail from "@/components/BotDeployConfigDetail";
import NlpTesterDatasetsDataGrid from "@/components/NlpTesterDatasetsDataGrid";
import NlpTesterCategoriesDataGrid from "@/components/NlpTesterCategoriesDataGrid";
import NlpTesterCategoryDetail from "@/components/NlpTesterCategoryDetail";
import NlpTesterCategoryDetailOverview from "@/components/NlpTesterCategoryDetailOverview";
import NlpTesterGuide from "@/components/NlpTesterGuide";
import NlpTesterModelsDataGrid from "@/components/NlpTesterModelsDataGrid";
import LineQueueDataGrid from "@/components/LineQueueDataGrid";
import LanguageDataGrid from "@/components/LanguageDataGrid";
import LanguageDetail from "@/components/LanguageDetail";
import LanguageOverview from "@/components/LanguageOverview";
import TagsDataGrid from "@/components/TagsDataGrid";
import Billing from "@/components/Billing";
import BillingOverview from "@/components/BillingOverview";
import BillingLinesDataGrid from "@/components/BillingLinesDataGrid";
import BillingTariffsDataGrid from "@/components/BillingTariffsDataGrid";
import VariablesStorage from "@/components/VariablesStorage";

const generatePageTitle = (title: string): string => {
  return `${title} - ${DEFAULT_TITLE}`;
};

const routesConfiguration: RouteConfig[] = [
  {
    path: "/",
    redirect: { name: Routes.FLOW_OVERVIEW },
  },
  {
    path: "/flows",
    alias: "/callscript",
    component: RouterNavigation,
    children: [
      {
        path: "",
        name: Routes.FLOW_OVERVIEW,
        component: FlowsDataGrid,
        meta: {
          title: generatePageTitle("Flows"),
        },
      },
      {
        path: "detail/:idCallScript",
        component: FlowDetail,
        meta: {
          permissions: [Namespaces.CALLSCRIPT, [Permissions.VIEW]],
        },
        children: [
          {
            path: "",
            redirect: { name: Routes.FLOW_DETAIL_BASIC },
          },
          {
            path: "basic",
            name: Routes.FLOW_DETAIL_BASIC,
            component: FlowOverview,
          },
          {
            path: "history",
            name: Routes.FLOW_DETAIL_VERSION_HISTORY,
            component: FlowVersionHistoryDataGrid,
          },
          {
            path: "dialLogs",
            name: Routes.FLOW_DETAIL_DIAL_LOGS,
            component: DialLogDataGrid,
            props: (route) => ({
              params: { idCallScript: route.params.idCallScript },
            }),
          },
        ],
      },
    ],
  },
  {
    path: "/campaigns",
    component: RouterNavigation,
    props: (route) => ({
      routerKey: `${route.name}${route.params.idCampaign}`,
    }),
    children: [
      {
        path: "",
        name: Routes.CAMPAIGN,
        component: CampaignsDataGrid,
        meta: {
          title: generatePageTitle("Campaigns"),
        },
      },
      {
        path: "detail/:idCampaign",
        name: Routes.CAMPAIGN_DETAIL,
        component: CampaignDashboard,
      },
    ],
  },
  {
    path: "/administration",
    component: RouterNavigation,
    children: [
      {
        path: "",
        name: Routes.ADMINISTRATION,
        component: AdministrationGuide,
        meta: {
          title: generatePageTitle("Administration"),
        },
      },
      {
        path: "modules",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.MODULE_OVERVIEW,
            component: ModulesDataGrid,
            meta: {
              title: generatePageTitle("Modules"),
            },
          },
          {
            path: "detail/:idDialModule",
            component: ModuleDetail,
            meta: {
              permissions: [Namespaces.MODULE, [Permissions.VIEW]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.MODULE_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.MODULE_DETAIL_BASIC,
                component: ModuleOverview,
              },
              {
                path: "lines",
                name: Routes.MODULE_DETAIL_LINES,
                component: LinesDataGrid,
                props: { isInsideDetail: true },
              },
              {
                path: "dialLogs",
                name: Routes.MODULE_DETAIL_DIAL_LOGS,
                component: DialLogDataGrid,
                props: (route) => ({
                  params: { idDialModule: route.params.idDialModule },
                  showFlow: true,
                  includesDifferentDialTypes: true,
                }),
              },
            ],
          },
        ],
      },
      {
        path: "lines",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.LINES_OVERVIEW,
            component: LinesDataGrid,
            meta: {
              title: generatePageTitle("Lines"),
            },
          },
          {
            path: "detail/:idDialModuleLine",
            component: LineDetail,
            meta: {
              permissions: [Namespaces.LINE, [Permissions.VIEW]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.LINE_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.LINE_DETAIL_BASIC,
                component: LineOverview,
              },
              {
                path: "monitoring",
                name: Routes.LINE_DETAIL_MONITORING,
                component: LineMonitoring,
              },
              {
                path: "statistics",
                name: Routes.LINE_DETAIL_STATISTICS,
                component: LineStatistics,
              },
              {
                path: "dialLogs",
                name: Routes.LINE_DETAIL_DIAL_LOGS,
                component: DialLogDataGrid,
                props: (route) => ({
                  params: { idDialModuleLine: route.params.idDialModuleLine },
                  showFlow: true,
                  includesDifferentDialTypes: true,
                }),
              },
              {
                path: "lineQueue",
                name: Routes.LINE_DETAIL_LINE_QUEUE,
                component: LineQueueDataGrid,
                props: (route) => ({
                  params: { idDialModuleLine: route.params.idDialModuleLine },
                }),
              },
            ],
          },
        ],
      },
      {
        path: "grammars",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.GRAMMARS_OVERVIEW,
            component: GrammarsDataGrid,
            meta: {
              title: generatePageTitle("Grammars"),
            },
          },
          {
            path: "detail/:idGrammar",
            component: GrammarDetail,
            meta: {
              permissions: [Namespaces.GRAMMAR, [Permissions.VIEW]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.GRAMMARS_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.GRAMMARS_DETAIL_BASIC,
                component: GrammarOverview,
              },
            ],
          },
        ],
      },
      {
        path: "users",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.USERS_OVERVIEW,
            component: UsersDataGrid,
            meta: {
              title: generatePageTitle("Users"),
            },
          },
          {
            path: "detail/:idUser",
            component: UserDetail,
            meta: {
              permissions: [
                Namespaces.ACCESS_MANAGEMENT,
                [Permissions.VIEW_ACCOUNT],
              ],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.USER_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.USER_DETAIL_BASIC,
                component: UserOverview,
              },
              {
                path: "security",
                name: Routes.USER_DETAIL_SECURITY,
                component: UserSecurity,
              },
            ],
          },
        ],
      },
      {
        path: "tenants",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.TENANTS_OVERVIEW,
            component: TenantsDataGrid,
            meta: {
              title: generatePageTitle("Tenants"),
            },
          },
          {
            path: "detail/:idTenant",
            component: TenantDetail,
            meta: {
              permissions: [
                Namespaces.ACCESS_MANAGEMENT,
                [Permissions.VIEW_ACCOUNT],
              ],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.TENANT_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.TENANT_DETAIL_BASIC,
                component: TenantOverview,
              },
              {
                path: "users",
                name: Routes.TENANT_DETAIL_USERS,
                component: UsersDataGrid,
                props: {
                  isInsideDetail: true,
                },
              },
              {
                path: "statistics",
                name: Routes.TENANT_DETAIL_STATISTICS,
                component: TenantStatistics,
              },
              {
                path: "notifications",
                name: Routes.TENANT_DETAIL_NOTIFICATIONS,
                component: TenantNotifications,
              },
            ],
          },
        ],
      },
      {
        path: "permission-groups",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.PERMISSION_GROUPS_OVERVIEW,
            component: PermissionGroupsDataGrid,
            meta: {
              title: generatePageTitle("Permission groups"),
            },
          },
          {
            path: "detail/:idRole",
            component: PermissionGroupDetail,
            meta: {
              permissions: [
                Namespaces.ACCESS_MANAGEMENT,
                [Permissions.VIEW_ROLE],
              ],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.PERMISSION_GROUP_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.PERMISSION_GROUP_DETAIL_BASIC,
                component: PermissionGroupOverview,
              },
            ],
          },
        ],
      },
      {
        path: "components",
        name: Routes.COMPONENTS,
        component: ComponentsDataGrid,
        meta: {
          title: generatePageTitle("Components"),
        },
        props: {
          isComponentsOnly: true,
        },
      },
      {
        path: "tenant-statistics",
        name: Routes.TENANT_STATISTICS_ALL,
        component: TenantStatisticsAll,
        meta: {
          title: generatePageTitle("Tenant statistics"),
          permissions: [Namespaces.STATISTICS, [Permissions.VIEW]],
        },
      },
      {
        path: "billing/:idTenant",
        component: Billing,
        children: [
          {
            path: "",
            redirect: { name: Routes.BILLING_OVERVIEW },
          },
          {
            path: "overview",
            name: Routes.BILLING_OVERVIEW,
            component: BillingOverview,
            meta: {
              title: generatePageTitle("Billing"),
              permissions: [Namespaces.STATISTICS, [Permissions.BILLING_VIEW]],
            },
          },
          {
            path: "tariffs",
            name: Routes.BILLING_TARIFFS,
            component: BillingTariffsDataGrid,
            meta: {
              title: generatePageTitle("Tariffs"),
              permissions: [
                Namespaces.ACCESS_MANAGEMENT,
                [Permissions.TARIFF_VIEW],
              ],
            },
          },
          {
            path: "lines",
            name: Routes.BILLING_LINES,
            component: BillingLinesDataGrid,
            meta: {
              title: generatePageTitle("Lines"),
              permissions: [
                Namespaces.ACCESS_MANAGEMENT,
                [Permissions.TARIFF_VIEW],
              ],
            },
          },
        ],
      },
      {
        path: "languages",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.LANGUAGES_OVERVIEW,
            component: LanguageDataGrid,
            meta: {
              title: generatePageTitle("Languages"),
              permissions: [Namespaces.LANGUAGE, [Permissions.VIEW]],
            },
          },
          {
            path: "detail/:idLanguage",
            component: LanguageDetail,
            children: [
              {
                path: "",
                redirect: { name: Routes.LANGUAGE_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.LANGUAGE_DETAIL_BASIC,
                component: LanguageOverview,
                meta: {
                  permissions: [Namespaces.LANGUAGE, [Permissions.VIEW]],
                },
              },
            ],
          },
        ],
      },
      {
        path: "tts-generator",
        name: Routes.TTS_GENERATOR,
        component: TtsGenerator,
        meta: {
          title: generatePageTitle("TTS Generator"),
        },
      },
      {
        path: "package-manager",
        component: PackageManager,
        meta: {
          title: generatePageTitle("Package manager"),
        },
        children: [
          {
            path: "",
            redirect: { name: Routes.PACKAGE_MANAGER_INSTALL },
          },
          {
            path: "install",
            name: Routes.PACKAGE_MANAGER_INSTALL,
            component: PackageManagerInstall,
          },
          {
            path: "create",
            name: Routes.PACKAGE_MANAGER_CREATE,
            component: PackageManagerCreate,
          },
        ],
      },
      {
        path: "audit",
        name: Routes.AUDIT_LOG,
        component: AuditDataGrid,
        meta: {
          title: generatePageTitle("Audit log"),
          permissions: [Namespaces.AUDIT_LOG, [Permissions.VIEW]],
        },
      },
      {
        path: "user-profile",
        component: UserProfileDetail,
        children: [
          {
            path: "",
            redirect: { name: Routes.USER_PROFILE_BASIC },
          },
          {
            path: "basic",
            name: Routes.USER_PROFILE_BASIC,
            component: UserOverview,
          },
          {
            path: "security",
            name: Routes.USER_PROFILE_SECURITY,
            component: UserSecurity,
          },
        ],
      },
      {
        path: "tags",
        component: TagsDataGrid,
        name: Routes.TAGS,
        meta: {
          title: generatePageTitle("Tags"),
        },
      },
      {
        path: "botdeploy",
        component: RouterNavigation,
        children: [
          {
            path: "",
            redirect: { name: Routes.BOTDEPLOY_MODULES },
          },
          {
            path: "overview",
            component: BotDeploy,
            children: [
              {
                path: "modules",
                name: Routes.BOTDEPLOY_MODULES,
                component: BotDeployModulesDataGrid,
                meta: {
                  title: generatePageTitle("Bot deploy - Modules"),
                },
              },
              {
                path: "servers",
                name: Routes.BOTDEPLOY_SERVERS,
                component: BotDeployServersDataGrid,
                meta: {
                  title: generatePageTitle("Bot deploy - Servers"),
                },
                props: (route) => ({
                  params: { name: route.params.name },
                }),
              },
              {
                path: "configurations",
                name: Routes.BOTDEPLOY_CONFIGS,
                component: BotDeployConfigsDataGrid,
                meta: {
                  title: generatePageTitle("Bot deploy - Configurations"),
                },
              },
            ],
          },
          {
            path: "module",
            component: RouterNavigation,
            children: [
              {
                path: "detail/:moduleName/:serverName",
                component: BotDeployModuleDetail,
                children: [
                  {
                    path: "",
                    redirect: { name: Routes.BOTDEPLOY_MODULE_DETAIL_BASIC },
                  },
                  {
                    path: "basic",
                    name: Routes.BOTDEPLOY_MODULE_DETAIL_BASIC,
                    component: BotDeployModuleOverview,
                  },
                ],
              },
            ],
          },
          {
            path: "server",
            component: RouterNavigation,
            children: [
              {
                path: "detail/:name",
                component: BotDeployServerDetail,
                children: [
                  {
                    path: "",
                    redirect: { name: Routes.BOTDEPLOY_SERVER_DETAIL_BASIC },
                  },
                  {
                    path: "basic",
                    name: Routes.BOTDEPLOY_SERVER_DETAIL_BASIC,
                    component: BotDeployServerOverview,
                  },
                ],
              },
            ],
          },
          {
            path: "config",
            component: RouterNavigation,
            children: [
              {
                path: "detail/:name",
                component: BotDeployConfigDetail,
                children: [
                  {
                    path: "",
                    redirect: { name: Routes.BOTDEPLOY_CONFIG_DETAIL_BASIC },
                  },
                  {
                    path: "basic",
                    name: Routes.BOTDEPLOY_CONFIG_DETAIL_BASIC,
                    component: BotDeployConfigOverview,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "nlp-tester",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.NLP_TESTER,
            component: NlpTesterGuide,
            meta: {
              title: generatePageTitle("NLP Tester"),
            },
          },
          {
            path: "datasets",
            component: RouterNavigation,
            children: [
              {
                path: "",
                name: Routes.NLP_TESTER_DATASETS,
                component: NlpTesterDatasetsDataGrid,
                meta: {
                  title: generatePageTitle("NLP datasets"),
                  permissions: [Namespaces.NLP, [Permissions.DATASET_VIEW]],
                },
              },
              {
                path: ":language/:datasetId/categories",
                name: Routes.NLP_TESTER_CATEGORIES,
                component: NlpTesterCategoriesDataGrid,
                meta: {
                  title: generatePageTitle("NLP categories"),
                  permissions: [Namespaces.NLP, [Permissions.CATEGORY_VIEW]],
                },
              },
              {
                path: ":language/:datasetId/categories/:category",
                component: NlpTesterCategoryDetail,
                children: [
                  {
                    path: "",
                    redirect: { name: Routes.NLP_TESTER_CATEGORY_DETAIL_BASIC },
                  },
                  {
                    path: "basic",
                    name: Routes.NLP_TESTER_CATEGORY_DETAIL_BASIC,
                    component: NlpTesterCategoryDetailOverview,
                    meta: {
                      title: generatePageTitle("NLP category"),
                      permissions: [
                        Namespaces.NLP,
                        [Permissions.CATEGORY_VIEW],
                      ],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "models",
            component: RouterNavigation,
            children: [
              {
                path: "",
                name: Routes.NLP_TESTER_MODELS,
                component: NlpTesterModelsDataGrid,
                meta: {
                  title: generatePageTitle("NLP models"),
                  permissions: [Namespaces.NLP, [Permissions.MODEL_VIEW]],
                },
              },
            ],
          },
        ],
      },
      {
        path: "variables-storage",
        name: Routes.VARIABLES_STORAGE,
        component: VariablesStorage,
        meta: {
          permissions: [Namespaces.TENANT_VALUE, [Permissions.VIEW]],
        },
      },
    ],
  },
  {
    path: "/conversation-details",
    name: Routes.CONVERSATION_DETAILS,
    component: DialLogDetail,
  },
  {
    path: "/reporting",
    name: Routes.REPORTING,
    component: Reporting,
  },
  {
    path: "/auth-refresh",
    component: RouterNavigation,
  },
  {
    path: "/auth-callback",
    component: RouterNavigation,
  },
  {
    path: "/not-authorized",
    component: NotAuthorized,
    name: Routes.NOT_AUTHORIZED,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default routesConfiguration;
