import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";

import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import toggleDialog from "@/helpers/toggleDialog";
import { hasNoSpecialChars, isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";

const NlpTesterCategoryCreateForm = Vue.extend({
  data: () => ({
    fields: {
      name: "",
    },
    isFormValid: true,
  }),

  methods: {
    async handleSubmit() {
      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: `${ApiEndpoints.NLP_TESTER_DATASET}/${this.$route.params?.language}/${this.$route.params?.datasetId}/category`,
        values: { name: this.fields.name.trim() },
        actionId: Actions.NLP_CATEGORY_CREATE,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.NLP_TESTER_CATEGORY_CREATE, {});

        this.$router.push({
          name: Routes.NLP_TESTER_CATEGORY_DETAIL_BASIC,
          params: {
            language: this.$route.params?.language,
            category: this.fields.name,
            datasetId: this.$route.params?.datasetId,
          },
        }),
          pushNotify(Notify.SUCCESS, "Category created successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.name}
          label="Name"
          rules={[isRequired, hasNoSpecialChars]}
        />
      </VForm>
    );
  },
});

export default NlpTesterCategoryCreateForm;
