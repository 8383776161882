import Vue, { VNode } from "vue";
import { VCheckbox, VIcon } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import { CALL_STATUS_ICONS } from "@/constants/valueIcons";
import { formatDate, formatTime } from "@/helpers/dateAndTimeUtils";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { Actions, Getters, Modules } from "@/models/store";

import AudioDialog from "../AudioDialog";
import AudioRecordRemoveDialog from "../AudioRecordRemoveDialog";
import DataGrid from "../DataGrid";

const DialLogDataGrid = Vue.extend({
  name: "DialLogDataGrid",
  props: {
    params: {},
    withElevation: {
      type: Boolean,
      default: false,
    },
    showFlow: {
      type: Boolean,
      default: false,
    },
    includesDifferentDialTypes: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dataGridConfig: {},
    flowType: 0,
    withFullConversation: true,
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [
      Getters.GET_DATAGRID_DATA,
      Getters.GET_DATAGRID_FILTER,
    ]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_FLOW]),
    ...mapGetters(Modules.USER, [Getters.APP_CONFIG, Getters.USER_INFO]),

    isDataGridFetching(): boolean {
      return (
        this.IS_ACTION_FETCHING(Actions.DIAL_LOGS_DATAGRID_FETCH) ||
        this.IS_ACTION_FETCHING(Actions.CONVERSATION_LOGS_DATAGRID_FETCH) ||
        this.IS_ACTION_FETCHING(Actions.DIAL_LOG_FETCH) ||
        this.IS_ACTION_FETCHING(Actions.AUDIO_RECORD_FETCH)
      );
    },

    getFlowType(): number {
      return this.INSPECTED_FLOW?.basic?.content?.type;
    },
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [
      Actions.DIAL_LOGS_DATAGRID_FETCH,
      Actions.DIAL_LOG_FETCH,
      Actions.CONVERSATION_LOGS_DATAGRID_FETCH,
    ]),
    ...mapActions(Modules.INSTANCES, [
      Actions.DIAL_LOG_FETCH,
      Actions.AUDIO_RECORD_OPEN,
      Actions.FLOW_OVERVIEW_FETCH,
      Actions.CAMPAIGN_OVERVIEW_FETCH,
      Actions.AUDIO_RECORD_DOWNLOAD,
    ]),

    async showLogDetail(guid: string, rowData: any): Promise<void> {
      const rawData = await this.DIAL_LOG_FETCH({
        guid,
        withFullConversation: this.withFullConversation,
      });

      toggleDialog(Dialogs.DIAL_LOG_DETAIL, {
        rawData,
        guid,
        withFullConversation: this.withFullConversation,
        ...rowData,
      });
    },

    checkDetailParams(): void {
      if (!this.$route.query.guid) {
        return;
      }

      const guid: any = this.$route.query.guid;
      const idTab: any = this.$route.query.tab || 0;

      this.showLogDetail(guid, { idTab });
    },

    getDataGridParams(isActive: boolean): any {
      const filters = this.withFullConversation
        ? this.GET_DATAGRID_FILTER(DataGrids.DIAL_LOGS)
        : this.GET_DATAGRID_FILTER(DataGrids.CONVERSATION_LOGS);

      return {
        params: this.params,
        filters: { ...filters, asConversations: isActive },
      };
    },

    setConfiguration(flowType: number): any {
      const dataGridConfig: DataGridConfig = {
        key: this.withFullConversation ? "guid" : "id",
        title: "Conversation log",
        disableElevation: !this.withElevation,
        headers:
          flowType === 1
            ? [
                { text: "Time", value: "callstart" },
                ...(this.showFlow
                  ? [{ text: "Flow", value: "callScript" }]
                  : []),
              ]
            : [
                { text: "Phone", value: "phone" },
                {
                  text: "State",
                  value: "state",
                },
                { text: "Time (start)", value: "callstart" },
                { text: "Direction", value: "direction" },
                {
                  text: "Duration",
                  value: "duration",
                },
                ...(this.showFlow
                  ? [{ text: "Flow", value: "callScript" }]
                  : []),
              ],
        rowActions: ({ guid, hasRecord, ...rowData }) => ({
          primary: {
            action: () => this.showLogDetail(guid, rowData),
            icon: "mdi-eye",
            title: "Show detail",
          },
          secondary: [
            {
              action: () => {
                this.AUDIO_RECORD_OPEN({
                  guid,
                  withFullConversation: this.withFullConversation,
                });
              },
              icon: "mdi-volume-high",
              title: "Play audio record",
              disabled: !hasRecord,
            },
            {
              action: () =>
                this.AUDIO_RECORD_DOWNLOAD({
                  guid,
                  withFullConversation: this.withFullConversation,
                }),
              icon: "mdi-download",
              title: "Download audio record",
              disabled: !hasRecord,
            },
            {
              action: () =>
                toggleDialog(Dialogs.DIAL_LOG_AUDIO_REMOVE, {
                  guid,
                  withFullConversation: this.withFullConversation,
                }),
              icon: "mdi-volume-off",
              title: "Remove audio record",
              disabled: !hasRecord,
              isAllowed: hasUserPermissions(Namespaces.CALLSCRIPT, [
                Permissions.RECORD_REMOVE,
              ]),
            },
          ],
        }),
        buttons: [
          {
            action: () => {
              const fetchParams = this.getDataGridParams(
                this.withFullConversation
              );

              this.withFullConversation
                ? this.CONVERSATION_LOGS_DATAGRID_FETCH(fetchParams)
                : this.DIAL_LOGS_DATAGRID_FETCH(fetchParams);
            },
            isFab: true,
            icon: "mdi-refresh",
          },
        ],
        columnModificators: {
          "item.callstart": ({ item }) => formatDate(item.callstart),
          "item.duration": ({ item }) => formatTime(item.duration),

          "item.direction": ({ item }) =>
            item.direction ? (
              <span>
                <VIcon small class="mr-2" color="warning">
                  mdi-phone-outgoing
                </VIcon>
                OUT
              </span>
            ) : (
              <span>
                <VIcon small class="mr-2" color="success">
                  mdi-phone-incoming
                </VIcon>
                IN
              </span>
            ),

          "item.callScript": ({ item }) => (
            <router-link
              class="tertiary--text"
              to={{
                name: Routes.FLOW_DETAIL_BASIC,
                params: { idCallScript: item.idCallScript },
              }}
            >
              {item.callScript}
            </router-link>
          ),

          "item.state": ({ item }) => (
            <span>
              <VIcon
                small
                class="mr-2"
                color={CALL_STATUS_ICONS[item.state]?.color}
              >
                {CALL_STATUS_ICONS[item.state]?.icon}
              </VIcon>
              {item.stateName}
            </span>
          ),
        },
        filters: [
          { name: "phone" },
          {
            name: "state",
            values: [
              { value: 1, text: "picked" },
              { value: 3, text: "listened to end" },
              { value: -1, text: "not picked" },
              { value: 11, text: "error" },
            ],
          },
          {
            name: "callstart",
            dateTimePickers: [
              { name: "from", label: "From" },
              { name: "to", label: "To" },
            ],
          },
          {
            name: "direction",
            values: [
              { value: false, text: "IN" },
              { value: true, text: "OUT" },
            ],
          },
        ],
      };

      return dataGridConfig;
    },

    switchConversations(isActive: boolean): void {
      const params = this.getDataGridParams(isActive);

      isActive
        ? this.CONVERSATION_LOGS_DATAGRID_FETCH(params)
        : this.DIAL_LOGS_DATAGRID_FETCH(params);
    },
  },

  watch: {
    getFlowType: function (val) {
      this.dataGridConfig = this.setConfiguration(val);
    },
  },

  created() {
    this.withFullConversation =
      this.USER_INFO?.consolePreference?.preferConversations;

    this.$route.query.asConversations === "false"
      ? (this.withFullConversation = false)
      : null;

    this.$route.query.asConversations === "true"
      ? (this.withFullConversation = true)
      : null;

    this.INSPECTED_FLOW?.basic?.content?.type &&
    !this.includesDifferentDialTypes
      ? (this.flowType = this.INSPECTED_FLOW?.basic?.content?.type)
      : null;

    this.dataGridConfig = this.setConfiguration(this.flowType);

    this.checkDetailParams();
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={
            this.withFullConversation && this.APP_CONFIG.conversations
              ? this.CONVERSATION_LOGS_DATAGRID_FETCH
              : this.DIAL_LOGS_DATAGRID_FETCH
          }
          fetchProps={this.params}
          data={
            this.withFullConversation && this.APP_CONFIG.conversations
              ? this.GET_DATAGRID_DATA(DataGrids.CONVERSATION_LOGS)
              : this.GET_DATAGRID_DATA(DataGrids.DIAL_LOGS)
          }
          configuration={this.dataGridConfig}
          isFetching={this.isDataGridFetching}
          withSearch
          scopedSlots={{
            headerContent: () => (
              <VCheckbox
                class="mr-5"
                vModel={this.withFullConversation}
                label="Full conversations"
                hideDetails
                disabled={this.isDataGridFetching}
                onChange={(isActive: boolean) =>
                  this.switchConversations(isActive)
                }
              />
            ),
          }}
        />
        <AudioDialog />
        <AudioRecordRemoveDialog />
      </div>
    );
  },
});

export default DialLogDataGrid;
