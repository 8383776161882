import ApiEndpoints from "@/constants/apiEndpoints";
import Dropdowns from "@/constants/dropdowns";
import Vue, { PropType, VNode } from "vue";
import DynamicSelect from "../DynamicSelect";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { mapGetters } from "vuex";
import { Getters, Modules } from "@/models/store";

const TenantSelect = Vue.extend({
  props: {
    value: Number,
    options: Object,
    label: String,
    permission: Array as PropType<string[]>,
  },

  computed: {
    ...mapGetters(Modules.USER, [Getters.PERMISSION_TENANTS_ID]),
  },

  methods: {
    getListByPermission(tenants: any) {
      if (!this.permission) {
        return tenants;
      }

      const [ns, perm] = this.permission;

      if (hasUserPermissions(ns, [perm], -1)) {
        return tenants;
      }

      const allowedTenants = this.PERMISSION_TENANTS_ID(`${ns}.${perm}`);

      return Object.fromEntries(
        Object.entries(tenants).filter(([key]) =>
          allowedTenants.includes(parseInt(key))
        )
      );
    },

    formatOptions(tenants: any) {
      const tenantsList = this.getListByPermission(tenants);

      return Object.entries(tenantsList).map(([key, value]) => ({
        text: value,
        value: parseInt(key),
      }));
    },
  },

  render(): VNode {
    return (
      <DynamicSelect
        name={
          this.permission
            ? Dropdowns.TENANTS_WITH_PERMISSION
            : Dropdowns.TENANTS
        }
        label={this.label ?? "Tenant"}
        options={this.options}
        fetchUrl={ApiEndpoints.TENANT_LIST}
        onInput={(val: number) => this.$emit("input", val)}
        value={this.value}
        dataModificator={this.formatOptions}
        isValueNumber
        useCache={false}
      />
    );
  },
});

export default TenantSelect;
