import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Notify from "@/constants/notifications";
import generateErrorMsg from "@/helpers/generateErrorMsg";
import pushNotify from "@/helpers/pushNotify";
import { isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCol,
  VForm,
  VIcon,
  VRow,
  VSelect,
  VSlider,
  VTextarea,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import LanguageWithVoiceSelect from "../LanguageWithVoiceSelect";
import AudioDialog from "../AudioDialog";
import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";

const TtsGenerator = Vue.extend({
  data: () => ({
    fields: {
      text: "",
      tempo: 1,
      pitch: 1,
      format: "wav",
    },
    isValid: true,
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    isFetching(): boolean {
      return this.IS_ACTION_FETCHING(Actions.TTS_GENERATOR);
    },
  },

  methods: {
    getLanguageConfig(): any {
      const refs: any = this.$refs;
      return refs.languageSelect.getLanguageConfig();
    },

    async handleSubmit(): Promise<void> {
      const { ttsLanguageCode, ttsEngine, ttsVoiceName } =
        this.getLanguageConfig();

      const { isFormValid, isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.TTS_GENERATOR,
        values: {
          ...this.fields,
          voice: {
            code: ttsLanguageCode,
            engine: ttsEngine,
            tts: ttsVoiceName,
          },
        },
        actionId: Actions.TTS_GENERATOR,
        responseType: "blob",
      });

      if (isFormSubmited) {
        const audioUrl = window.URL.createObjectURL(data);
        toggleDialog(Dialogs.DIAL_LOG_AUDIO, { audio: audioUrl, blob: data });
      }

      if (isFormValid && !isFormSubmited) {
        pushNotify(Notify.ERROR, generateErrorMsg(Actions.TTS_GENERATOR));
      }
    },
  },

  render(): VNode {
    return (
      <div>
        <VRow>
          <VCol cols={12} md={10} offsetMd={1}>
            <h2 class="text-h4 mb-5 font-weight-bold primary--text">
              TTS Generator
            </h2>
            <VCard loading={this.isFetching}>
              <VCardText>
                <VForm ref="form" vModel={this.isValid}>
                  <VTextarea
                    label="Enter text"
                    outlined
                    vModel={this.fields.text}
                    rules={[isRequired]}
                    spellcheck={false}
                  />
                  <VRow>
                    <VCol md={6}>
                      <LanguageWithVoiceSelect
                        ref="languageSelect"
                        value="cs"
                        outlined
                      />
                    </VCol>
                    <VCol md={6}>
                      <VSlider
                        vModel={this.fields.tempo}
                        label="Tempo"
                        thumbLabel="always"
                        min="-2"
                        max="2"
                        step="0.01"
                        class="mt-3"
                      />
                      <VSlider
                        vModel={this.fields.pitch}
                        label="Pitch"
                        thumbLabel="always"
                        min="-2"
                        max="2"
                        step="0.01"
                        class="mt-8"
                      />
                    </VCol>
                  </VRow>
                  <VRow class="mt-0">
                    <VCol md={6} class="pt-0">
                      <VSelect
                        outlined
                        label="Format"
                        vModel={this.fields.format}
                        items={["wav", "ALAW"]}
                      />
                    </VCol>
                  </VRow>
                </VForm>
                <div class="d-flex justify-end">
                  <VBtn
                    onClick={this.handleSubmit}
                    rounded
                    large
                    class="tertiary white--text"
                    disabled={this.isFetching}
                  >
                    <VIcon class="mr-1">mdi-download</VIcon>Generate
                  </VBtn>
                </div>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
        <AudioDialog />
      </div>
    );
  },
});

export default TtsGenerator;
