import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VDivider,
  VIcon,
  VList,
  VListItem,
  VListItemContent,
  VListItemIcon,
  VListItemTitle,
  VMenu,
  VSimpleTable,
  VSkeletonLoader,
  VSpacer,
} from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

import ApiEndpoints from "@/constants/apiEndpoints";
import CAMPAIGN_STATUS from "@/constants/campaignStatus";
import DAYS_IN_WEEK from "@/constants/daysInWeek";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";

import CampaignChangeStateDialog from "../CampaignChangeStateDialog";
import CampaignCreateDialog from "../CampaignCreateDialog";
import CampaignEditDialog from "../CampaignEditDialog";
import CampaignErrorContactsDialog from "../CampaignErrorContactsDialog";
import CampaignManageContacts from "../CampaignManageContacts";
import CampaignRemoveDialog from "../CampaignRemoveDialog";
import CampaignValidationDialog from "../CampaignValidationDialog";
import CsvExportDialog from "../CsvExportDialog";
import DialLogDataGrid from "../DialLogDataGrid";
import DialLogDetailDialog from "../DialLogDetailDialog";
import DoughnutChart from "../DoughnutChart";
import CampaignDataCard from "./CampaignDataCard";
import CampaignLink from "./CampaignLink";
import CampaignOverviewListItem from "./CampaignOverviewListItem";
import CampaignResultValue from "./CampaignResultValue";

const CampaignDashboard = Vue.extend({
  data: () => ({
    campaignActionsConfig: {},
    statusWebSocket: {} as any,
    chartData: {
      labels: ["January", "February", "March"],
      datasets: [{ data: [40, 20, 12] }],
    },
    chartOptions: {
      responsive: true,
    },
    resultStep: 0,
    isTableModeActive: false,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_CAMPAIGN]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    dashboardData(): any {
      return this.INSPECTED_CAMPAIGN?.basic;
    },

    campaignActions(): any {
      const {
        edit,
        remove,
        pause,
        finish,
        run,
        importContacts,
        validation,
        duplicate,
        changeView,
      }: any = this.campaignActionsConfig;

      switch (this.INSPECTED_CAMPAIGN?.basic?.status) {
        case 1:
          return {
            primary: run,
            secondary: [
              edit,
              validation,
              importContacts,
              duplicate,
              changeView,
              remove,
            ],
          };
        case 5:
        case 8: {
          return { primary: pause, secondary: [duplicate, changeView] };
        }
        case 7: {
          return {
            primary: run,
            secondary: [
              finish,
              edit,
              validation,
              importContacts,
              duplicate,
              changeView,
              remove,
            ],
          };
        }
        case 9: {
          return { secondary: [duplicate, changeView, remove] };
        }
        default:
          return { primary: null, secondary: null };
      }
    },

    resultsByStep(): any {
      if (!this.dashboardData || !this.dashboardData.result) {
        return {};
      }

      const { partialResults, ...totalResult } = this.dashboardData.result;
      return this.resultStep === 0
        ? totalResult
        : partialResults[this.resultStep - 1];
    },

    arePartialResults(): boolean {
      return this.dashboardData?.result?.partialResults?.length > 0;
    },

    daysInWeek(): string {
      if (
        !this.dashboardData?.callValidDays ||
        this.dashboardData?.callValidDays?.length === 0
      ) {
        return "-";
      }

      const dow = this.dashboardData.callValidDays.map((dayIndex: string) =>
        DAYS_IN_WEEK.find(({ value }) => value === parseInt(dayIndex))
      );

      return dow.map(({ text }: any) => text.slice(0, 3))?.join(", ");
    },

    currentConversion(): any {
      const currentRatio = this.resultsByStep?.uniqueCallsProcessed / 100;

      const pickedUp = (this.resultsByStep?.pickedUp / currentRatio).toFixed(2);
      const notPicked = (this.resultsByStep?.notPicked / currentRatio).toFixed(
        2
      );

      return { pickedUp, notPicked };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.CAMPAIGN_OVERVIEW_FETCH,
      Actions.CAMPAING_STATUS_UPDATE,
    ]),

    initWebSocket() {
      this.statusWebSocket = new WebSocket(
        `${ApiEndpoints.CAMPAIGN_DETAIL_WS}?idCampaign=${this.$route.params.idCampaign}`
      );

      this.statusWebSocket.onmessage = ({ data }: any) => {
        const recievedStatus = JSON.parse(data)?.status;

        if (!recievedStatus) {
          return null;
        }

        this.CAMPAING_STATUS_UPDATE(recievedStatus);
      };
    },

    switchResultStep(nextDirection: boolean) {
      const nextStep = nextDirection
        ? this.resultStep + 1
        : this.resultStep - 1;

      if (nextStep > this.dashboardData.result.partialResults.length) {
        this.resultStep = 0;
        return;
      }

      if (nextStep < 0) {
        this.resultStep = this.dashboardData.result.partialResults.length;
        return;
      }

      this.resultStep = nextStep;
    },

    changeView() {
      this.isTableModeActive = !this.isTableModeActive;
      this.$router.push({
        name: this.$route.name ?? "",
        query: {
          ...this.$route.query,
          viewMode: this.isTableModeActive ? "1" : "0",
        },
      });
    },

    getConversionPerex(attr: string): any {
      if (
        this.resultsByStep?.uniqueCallsProcessed === 0 ||
        this.resultsByStep?.remaining === 0
      ) {
        return "";
      }

      return `${this.currentConversion[attr]}% from ${this.resultsByStep?.uniqueCallsProcessed} processed`;
    },
  },

  created() {
    this.CAMPAIGN_OVERVIEW_FETCH({ idCampaign: this.$route.params.idCampaign });

    this.$route.query.viewMode === "1" ? (this.isTableModeActive = true) : null;

    try {
      this.initWebSocket();
    } catch (e) {
      console.error(e);
    }

    this.campaignActionsConfig = {
      edit: {
        title: "Edit",
        icon: "mdi-pencil",
        action: () => toggleDialog(Dialogs.CAMPAIGN_EDIT, {}),
      },
      remove: {
        title: "Remove",
        icon: "mdi-delete",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_REMOVE, {
            idCampaign: this.$route.params.idCampaign,
            isDetailActive: true,
          }),
      },
      pause: {
        title: "Pause",
        icon: "mdi-pause",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_STATE_CHANGE, {
            idCampaign: this.$route.params.idCampaign,
            text: "Do you really want to pause the campaign?",
            desiredStatus: 7,
          }),
      },
      run: {
        title: "Run",
        icon: "mdi-play",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_STATE_CHANGE, {
            idCampaign: this.$route.params.idCampaign,
            text: "Do you really want to run the campaign?",
            desiredStatus: 5,
          }),
      },
      finish: {
        title: "Finish",
        icon: "mdi-flag-checkered",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_STATE_CHANGE, {
            idCampaign: this.$route.params.idCampaign,
            text: "Do you really want to finish campaign? Editation will be no longer possible.",
            desiredStatus: 9,
          }),
      },
      importContacts: {
        title: "Import contacts",
        icon: "mdi-import",
        action: () =>
          toggleDialog(Dialogs.CAMPAIGN_MANAGE_CONTACTS, {
            idCampaign: this.$route.params.idCampaign,
          }),
      },
      validation: {
        title: "Validation",
        icon: "mdi-phone",
        action: () => toggleDialog(Dialogs.CAMPAIGN_VALIDATION_DIALOG),
      },
      duplicate: {
        title: "Use as a template",
        icon: "mdi-content-copy",
        action: () => toggleDialog(Dialogs.CAMPAIGN_CREATE, {}),
      },
      changeView: {
        title: "Change view",
        icon: "mdi-view-dashboard",
        action: () => this.changeView(),
      },
    };
  },

  beforeDestroy() {
    this.statusWebSocket.close();
  },

  render(): VNode {
    return (
      <div class="campaign-dashboard">
        <div class="d-flex align-center">
          <h2 class="text-h4 font-weight-bold primary--text mb-5">
            {this.dashboardData?.name || "-"}
          </h2>
        </div>
        <div class="d-flex">
          <VBtn
            rounded
            class="secondary primary--text"
            onClick={() => this.$router.push({ name: Routes.CAMPAIGN })}
          >
            <VIcon left color="primary">
              mdi-arrow-left
            </VIcon>
            Back
          </VBtn>
          <VSpacer />
          {this.campaignActions?.primary && (
            <VBtn
              rounded
              class="tertiary white--text ml-3"
              onClick={this.campaignActions.primary.action}
            >
              <VIcon left color="white">
                {this.campaignActions.primary.icon}
              </VIcon>
              {this.campaignActions.primary.title}
            </VBtn>
          )}

          <VMenu
            offset-y
            scopedSlots={{
              activator: ({ on, attrs }: never) => (
                <VBtn
                  rounded
                  class="secondary primary--text ml-3"
                  {...{ on, attrs }}
                >
                  <VIcon>mdi-dots-horizontal</VIcon>
                </VBtn>
              ),
            }}
          >
            <VList dense>
              {this.campaignActions?.secondary &&
                this.campaignActions?.secondary.map(
                  ({ title, icon, action }: any) => (
                    <VListItem onClick={() => action()}>
                      <VListItemIcon>
                        <VIcon color="primary">{icon}</VIcon>
                      </VListItemIcon>
                      <VListItemContent>
                        <VListItemTitle>{title}</VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                  )
                )}
            </VList>
          </VMenu>
        </div>
        {this.IS_ACTION_FETCHING(Actions.CAMPAIGN_OVERVIEW_FETCH) ? (
          <VSkeletonLoader class="mt-5 mb-5" type="table" />
        ) : (
          <div class="d-flex mt-5 mb-5">
            <VCard class="mr-5" style="flex: 1">
              <VCardTitle class="d-flex align-center primary--text font-weight-bold text-h5">
                Campaign parameters
              </VCardTitle>
              <VCardText>
                <VList>
                  <CampaignOverviewListItem title="Flow">
                    <CampaignLink
                      title={this.dashboardData?.callscript}
                      routeName={Routes.FLOW_DETAIL_BASIC}
                      routeParamName="idCallScript"
                      routeParamValue={this.dashboardData?.idCallscript}
                    />
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Line">
                    <CampaignLink
                      title={this.dashboardData?.line}
                      routeName={Routes.LINE_DETAIL_BASIC}
                      routeParamName="idDialModuleLine"
                      routeParamValue={this.dashboardData?.idLine}
                    />
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Tenant">
                    <CampaignLink
                      title={this.dashboardData?.tenant}
                      routeName={Routes.TENANT_DETAIL_BASIC}
                      routeParamName="idTenant"
                      routeParamValue={this.dashboardData?.idTenant}
                    />
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Ongoing">
                    <VIcon
                      color={this.dashboardData?.ongoing ? "success" : "error"}
                    >
                      {this.dashboardData?.ongoing ? "mdi-check" : "mdi-close"}
                    </VIcon>
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Start">
                    {this.dashboardData?.start
                      ? formatDate(this.dashboardData?.start)
                      : "Immediately"}
                  </CampaignOverviewListItem>

                  {this.dashboardData?.end && (
                    <CampaignOverviewListItem
                      title="End"
                      valueTooltip={
                        this.dashboardData?.ongoing
                          ? null
                          : "Date and time of immediate termination of the campaign"
                      }
                    >
                      {formatDate(this.dashboardData?.end)}
                    </CampaignOverviewListItem>
                  )}

                  <CampaignOverviewListItem title="Time range">
                    {this.dashboardData?.callFromDayTime}
                    &nbsp;-&nbsp;
                    {this.dashboardData?.callToDayTime}
                  </CampaignOverviewListItem>
                  <CampaignOverviewListItem title="Days in week">
                    {this.daysInWeek}
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Number of call attemps">
                    {this.dashboardData?.numberOfCallAttemps || ""}
                  </CampaignOverviewListItem>

                  <CampaignOverviewListItem title="Retry call after">
                    {isNaN(this.dashboardData?.retryCallAfterSeconds)
                      ? "N/A"
                      : `${this.dashboardData?.retryCallAfterSeconds / 60} min`}
                  </CampaignOverviewListItem>
                </VList>
              </VCardText>
            </VCard>
            <div style="flex: 2">
              <div class="d-flex">
                <CampaignDataCard
                  title="Status"
                  value={this.dashboardData?.statusName || "N/A"}
                  valueColor={
                    CAMPAIGN_STATUS[this.dashboardData?.status]?.color ||
                    "primary"
                  }
                  icon={CAMPAIGN_STATUS[this.dashboardData?.status]?.icon}
                  iconColor={CAMPAIGN_STATUS[this.dashboardData?.status]?.color}
                />
                <CampaignDataCard
                  title="Contacts count"
                  value={this.dashboardData?.result?.contacts ?? "-"}
                  icon="mdi-account-box"
                />
                <CampaignDataCard
                  title="Processed calls"
                  value={this.dashboardData?.dialLogCount ?? "-"}
                  icon="mdi-phone"
                  noRightMargin
                  hint="Sum of call attempts in all rounds combined."
                />
              </div>
              {this.isTableModeActive ? (
                <div class="d-flex mt-5">
                  <VCard style="flex: 1">
                    <VCardText>
                      <VSimpleTable>
                        <thead>
                          <tr>
                            <th></th>
                            {this.dashboardData?.result?.partialResults.map(
                              (_: any, index: number) => (
                                <th>{`Round ${index + 1}`}</th>
                              )
                            )}
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span class="font-weight-bold d-flex align-center">
                                <VIcon color="primary" class="pr-2">
                                  mdi-check-circle
                                </VIcon>
                                Contact processed
                              </span>
                            </td>
                            {this.dashboardData?.result?.partialResults.map(
                              (item: any) => (
                                <td>
                                  <CampaignResultValue
                                    value={item?.uniqueCallsProcessed}
                                    avg={item?.uniqueCallsProcessedAvg}
                                  />
                                </td>
                              )
                            )}
                            <td>
                              <span class="primary--text font-weight-bold">
                                <CampaignResultValue
                                  value={
                                    this.dashboardData?.result
                                      ?.uniqueCallsProcessed
                                  }
                                  avg={
                                    this.dashboardData?.result
                                      ?.uniqueCallsProcessedAvg
                                  }
                                />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="font-weight-bold">
                                <VIcon color="rgb(216, 216, 216)" class="pr-2">
                                  mdi-progress-clock
                                </VIcon>
                                Remaining contacts
                              </span>
                            </td>
                            {this.dashboardData?.result?.partialResults.map(
                              (item: any) => (
                                <td>
                                  <CampaignResultValue
                                    value={item?.remaining}
                                    avg={item?.remainingAvg}
                                  />
                                </td>
                              )
                            )}
                            <td>
                              <span class="primary--text font-weight-bold">
                                <CampaignResultValue
                                  value={this.dashboardData?.result?.remaining}
                                  avg={this.dashboardData?.result?.remainingAvg}
                                />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="font-weight-bold">
                                <VIcon color="success" class="pr-2">
                                  mdi-phone
                                </VIcon>
                                Picked
                              </span>
                            </td>
                            {this.dashboardData?.result?.partialResults.map(
                              (item: any) => (
                                <td>
                                  <CampaignResultValue
                                    value={item?.pickedUp}
                                    avg={item?.pickedAvg}
                                  />
                                </td>
                              )
                            )}
                            <td>
                              <span class="primary--text font-weight-bold">
                                <CampaignResultValue
                                  value={this.dashboardData?.result?.pickedUp}
                                  avg={this.dashboardData?.result?.pickedAvg}
                                />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="font-weight-bold">
                                <VIcon color="error" class="pr-2">
                                  mdi-phone-hangup
                                </VIcon>
                                Not picked
                              </span>
                            </td>
                            {this.dashboardData?.result?.partialResults.map(
                              (item: any) => (
                                <td>
                                  <CampaignResultValue
                                    value={item?.notPicked}
                                    avg={item?.notPickedAvg}
                                  />
                                </td>
                              )
                            )}
                            <td>
                              <span class="primary--text font-weight-bold">
                                <CampaignResultValue
                                  value={this.dashboardData?.result?.notPicked}
                                  avg={this.dashboardData?.result?.notPickedAvg}
                                />
                              </span>
                            </td>
                          </tr>
                          {[...Array(3)].map((_: any, index: number) => (
                            <tr>
                              <td>
                                <span class="font-weight-bold">
                                  <VIcon class="pr-2">mdi-bullseye-arrow</VIcon>
                                  Target {index + 1}
                                </span>
                              </td>
                              {this.dashboardData?.result?.partialResults.map(
                                (item: any) => (
                                  <td>
                                    <CampaignResultValue
                                      value={item?.[`target${index + 1}`]}
                                      avg={item?.[`target${index + 1}avg`]}
                                    />
                                  </td>
                                )
                              )}
                              <td>
                                <span class="primary--text font-weight-bold">
                                  <CampaignResultValue
                                    value={
                                      this.dashboardData?.result?.[
                                        `target${index + 1}`
                                      ]
                                    }
                                    avg={
                                      this.dashboardData?.result?.[
                                        `target${index + 1}avg`
                                      ]
                                    }
                                  />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </VSimpleTable>
                    </VCardText>
                  </VCard>
                </div>
              ) : (
                <div class="d-flex mt-5">
                  <VCard class="mr-5" style="flex: 1">
                    <VCardText>
                      <DoughnutChart
                        centerValue={
                          this.resultStep === 0
                            ? `${
                                this.resultsByStep.campaignProgressAvg
                                  ? Math.round(
                                      this.resultsByStep.campaignProgressAvg
                                    )
                                  : 0
                              }%`
                            : `${
                                this.resultsByStep.uniqueCallsProcessedAvg
                                  ? Math.round(
                                      this.resultsByStep.uniqueCallsProcessedAvg
                                    )
                                  : 0
                              }%`
                        }
                        centerValuePerex={
                          this.resultStep === 0
                            ? "Campaign progress"
                            : "Contacts processed"
                        }
                        centerValueClass={
                          this.resultStep === 0
                            ? "chart__center-text--long"
                            : ""
                        }
                        chartData={{
                          labels:
                            this.resultStep === 0
                              ? [
                                  "Picked",
                                  "Remaining",
                                  "Not Picked",
                                  "Not started",
                                ]
                              : ["Picked", "Not Picked", "Remaining"],
                          datasets: [
                            {
                              backgroundColor:
                                this.resultStep === 0
                                  ? ["#17c57c", "#fb8c00", "#f0545f", "#d8d8d8"]
                                  : ["#17c57c", "#f0545f", "#d8d8d8"],
                              data:
                                this.resultStep === 0
                                  ? [
                                      this.resultsByStep.pickedUp ?? 0,
                                      this.resultsByStep
                                        .calledUnfinishedContacts ?? 0,
                                      this.resultsByStep.notPicked ?? 0,
                                      this.resultsByStep.uncalledContacts ?? 0,
                                    ]
                                  : [
                                      this.resultsByStep.pickedUp ?? 0,
                                      this.resultsByStep.notPicked ?? 0,
                                      this.resultsByStep.remaining ?? 0,
                                    ],
                            },
                          ],
                        }}
                      />
                    </VCardText>
                  </VCard>
                  <VCard style="flex: 1">
                    <VCardTitle class="justify-space-between">
                      {this.arePartialResults && (
                        <VBtn
                          xSmall
                          fab
                          onClick={() => this.switchResultStep(false)}
                        >
                          <VIcon>mdi-chevron-left</VIcon>
                        </VBtn>
                      )}
                      <span class="primary--text">
                        {this.resultStep === 0
                          ? "Total result"
                          : `Round ${this.resultStep}`}
                      </span>
                      {this.arePartialResults && (
                        <VBtn
                          xSmall
                          fab
                          onClick={() => this.switchResultStep(true)}
                        >
                          <VIcon>mdi-chevron-right</VIcon>
                        </VBtn>
                      )}
                    </VCardTitle>
                    <VCardText>
                      {this.resultStep === 0 ? (
                        <CampaignOverviewListItem
                          title="Campaign progress"
                          icon={{
                            value: "mdi-timelapse",
                            color: "tertiary",
                          }}
                        >
                          <CampaignResultValue
                            value={`${this.resultsByStep.campaignProgressAvg}%`}
                          />
                        </CampaignOverviewListItem>
                      ) : (
                        <div>
                          <CampaignOverviewListItem
                            title="Contacts processed"
                            icon={{
                              value: "mdi-check-circle",
                              color: "primary",
                            }}
                          >
                            <CampaignResultValue
                              value={this.resultsByStep.uniqueCallsProcessed}
                              avg={this.resultsByStep.uniqueCallsProcessedAvg}
                            />
                          </CampaignOverviewListItem>
                          <CampaignOverviewListItem
                            title="Remaining contacts"
                            icon={{
                              value: "mdi-progress-clock",
                              color: "#d8d8d8",
                            }}
                          >
                            <CampaignResultValue
                              value={this.resultsByStep.remaining}
                              avg={this.resultsByStep.remainingAvg}
                            />
                          </CampaignOverviewListItem>
                        </div>
                      )}

                      <VDivider class="my-2" />
                      <CampaignOverviewListItem
                        title="Picked"
                        icon={{ value: "mdi-phone", color: "success" }}
                      >
                        <CampaignResultValue
                          value={this.resultsByStep.pickedUp}
                          avg={this.resultsByStep.pickedAvg}
                          perex={this.getConversionPerex("pickedUp")}
                        />
                      </CampaignOverviewListItem>
                      <CampaignOverviewListItem
                        title="Not picked"
                        icon={{ value: "mdi-phone-hangup", color: "error" }}
                      >
                        <CampaignResultValue
                          value={this.resultsByStep.notPicked}
                          avg={this.resultsByStep.notPickedAvg}
                          perex={
                            this.resultStep === 0
                              ? null
                              : this.getConversionPerex("notPicked")
                          }
                        />
                      </CampaignOverviewListItem>
                      {this.resultStep === 0 && (
                        <div>
                          <CampaignOverviewListItem
                            title="Remaining"
                            icon={{ value: "mdi-phone-sync", color: "warning" }}
                          >
                            <CampaignResultValue
                              value={
                                this.resultsByStep.calledUnfinishedContacts
                              }
                              avg={
                                this.resultsByStep.calledUnfinishedContactsAvg
                              }
                            />
                          </CampaignOverviewListItem>
                          <CampaignOverviewListItem
                            title="Not started"
                            icon={{
                              value: "mdi-progress-clock",
                              color: "#d8d8d8",
                            }}
                          >
                            <CampaignResultValue
                              value={this.resultsByStep.uncalledContacts}
                              avg={this.resultsByStep.uncalledContactsAvg}
                            />
                          </CampaignOverviewListItem>
                        </div>
                      )}
                      <VDivider class="my-2" />
                      <CampaignOverviewListItem
                        title="Target 1"
                        icon={{ value: "mdi-bullseye-arrow" }}
                      >
                        <CampaignResultValue
                          value={this.resultsByStep.target1}
                          avg={this.resultsByStep.target1avg}
                        />
                      </CampaignOverviewListItem>
                      <CampaignOverviewListItem
                        title="Target 2"
                        icon={{ value: "mdi-bullseye-arrow" }}
                      >
                        <CampaignResultValue
                          value={this.resultsByStep.target2}
                          avg={this.resultsByStep.target2avg}
                        />
                      </CampaignOverviewListItem>
                      <CampaignOverviewListItem
                        title="Target 3"
                        icon={{ value: "mdi-bullseye-arrow" }}
                      >
                        <CampaignResultValue
                          value={this.resultsByStep.target3}
                          avg={this.resultsByStep.target3avg}
                        />
                      </CampaignOverviewListItem>
                    </VCardText>
                  </VCard>
                </div>
              )}
            </div>
          </div>
        )}
        <div class="d-flex justify-end mt-15">
          <VBtn
            class="secondary primary--text spacing-playground mr-4 mb-4"
            onClick={() => {
              toggleDialog(Dialogs.CAMPAIGN_CSV_OVERVIEW, {
                customFileName: `Campaign_Overview_${this.$route.params.idCampaign}`,
                fetchParams: {
                  idCampaign: parseInt(this.$route.params.idCampaign),
                },
              });
            }}
            rounded
          >
            <VIcon left>mdi-file-download</VIcon>
            Overview CSV{" "}
          </VBtn>
          <VBtn
            class="secondary primary--text spacing-playground mr-4 mb-4"
            onClick={() => {
              toggleDialog(Dialogs.CAMPAIGN_CSV_EXPORT, {
                customFileName: `Campaign_AllData_${this.$route.params.idCampaign}`,
                fetchParams: {
                  idCampaign: parseInt(this.$route.params.idCampaign),
                  aggregated: false,
                },
              });
            }}
            rounded
          >
            <VIcon left>mdi-file-download</VIcon>
            All data CSV{" "}
          </VBtn>
          <VBtn
            class="tertiary white--text spacing-playground mb-4"
            onClick={() => {
              toggleDialog(Dialogs.CAMPAIGN_CSV_EXPORT, {
                customFileName: `Campaign_Results_${this.$route.params.idCampaign}`,
                fetchParams: {
                  idCampaign: parseInt(this.$route.params.idCampaign),
                  aggregated: true,
                },
              });
            }}
            rounded
          >
            <VIcon left>mdi-microsoft-excel</VIcon>
            Results report CSV{" "}
          </VBtn>
        </div>
        <DialLogDataGrid
          params={{
            idCampaign: this.$route.params.idCampaign,
          }}
          withElevation
        />
        <CampaignEditDialog />
        <CampaignRemoveDialog />
        <CampaignChangeStateDialog />
        <CampaignManageContacts />
        <CampaignValidationDialog idCampaign={this.$route.params.idCampaign} />
        <CampaignErrorContactsDialog />
        <CsvExportDialog
          name={Dialogs.CAMPAIGN_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_CAMPAIGN_STATISTICS}
          withoutRange
        />
        <CsvExportDialog
          name={Dialogs.CAMPAIGN_CSV_OVERVIEW}
          apiEndpoint={ApiEndpoints.CSV_CAMPAIGN_OVERVIEW}
          withoutRange
        />
        <DialLogDetailDialog />
        <CampaignCreateDialog prefilled />
      </div>
    );
  },
});

export default CampaignDashboard;
