import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VChip,
  VCol,
  VIcon,
  VList,
  VListItem,
  VListItemContent,
  VListItemIcon,
  VRow,
  VSkeletonLoader,
  VSpacer,
  VToolbar,
  VToolbarTitle,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import UserRolesDialog from "../UserRolesDialog";
import Dropdowns from "@/constants/dropdowns";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const UserRolesOverview = Vue.extend({
  props: {
    data: Array,
  },

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
    ...mapGetters(Modules.DROPDOWNS, [Getters.GET_DROPDOWN_DATA]),
  },

  methods: {
    getRoleTenants(idTenants: number[]) {
      if (idTenants[0] === -1) {
        return ["All tenants"];
      }

      const tenants = this.GET_DROPDOWN_DATA(Dropdowns.TENANTS);

      const tenantsList = idTenants.map((id) => {
        const tenantConfig = tenants.find((tenant: any) => tenant.value === id);
        return tenantConfig?.text;
      });

      return tenantsList;
    },
  },

  render(): VNode {
    return (
      <div>
        <VCard flat>
          <VToolbar flat>
            <VToolbarTitle
              class="primary--text font-weight-bold text-h5"
              inset
              vertical
            >
              Permission groups
            </VToolbarTitle>
            <VSpacer />
            {hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
              Permissions.EDIT_ACCOUNT_PERMISSION,
            ]) && (
              <VBtn
                small
                fab
                color="primary"
                onClick={() => toggleDialog(Dialogs.USER_ROLES, {})}
                class="ml-2 tertiary"
              >
                <VIcon>mdi-pencil</VIcon>
              </VBtn>
            )}
          </VToolbar>
          <VCardText>
            {this.IS_ACTION_FETCHING(Actions.USER_ROLES_FETCH) ? (
              <VSkeletonLoader type="chip" />
            ) : (
              <div>
                {this.data?.length > 0 ? (
                  <VList dense>
                    <VRow>
                      {this.data.map(({ name, idTenants }: any) => (
                        <VCol col={12} md={6} class="py-0">
                          <VListItem>
                            <VListItemIcon>
                              <VIcon color="success">mdi-check-circle</VIcon>
                            </VListItemIcon>
                            <VListItemContent>
                              {name}
                              <div class="font-italic text-caption">
                                {this.getRoleTenants(idTenants)?.map((role) => (
                                  <VChip xSmall class="mr-1">
                                    {role}
                                  </VChip>
                                ))}
                              </div>
                            </VListItemContent>
                          </VListItem>
                        </VCol>
                      ))}
                    </VRow>
                  </VList>
                ) : (
                  <span>There are no connected roles to the user.</span>
                )}
              </div>
            )}
          </VCardText>
        </VCard>

        <UserRolesDialog />
      </div>
    );
  },
});

export default UserRolesOverview;
