import Vue, { VNode } from "vue";

const CampaignResultValue = Vue.extend({
  props: {
    value: [Number, String],
    avg: Number,
    perex: String,
  },

  render(): VNode {
    return (
      <span class="text-right">
        {this.value ?? "-"}{" "}
        {this.avg !== undefined && (
          <span class="font-italic">({`${this.avg?.toFixed(2)}%` ?? "-"})</span>
        )}
        {this.perex && (
          <div
            class="font-italic text-center font-weight-regular"
            style="color: rgba(0, 0, 0, 0.6); font-size: 13px;"
          >
            {this.perex}
          </div>
        )}
      </span>
    );
  },
});

export default CampaignResultValue;
