import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import useLineActions from "@/hooks/useLineActions";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import ChangeModuleDialog from "@/components/ChangeModuleDialog";
import LineEnableDialog from "@/components/LineEnableDialog";
import LineRemoveDialog from "@/components/LineRemoveDialog";
import CsvExportDialog from "../CsvExportDialog";
import Dialogs from "@/constants/dialogs";
import ApiEndpoints from "@/constants/apiEndpoints";
import DialLogDetailDialog from "../DialLogDetailDialog";

const LineDetail = Vue.extend({
  name: "LineDetail",
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),

    instanceData(): any {
      const content = this.INSPECTED_LINE?.basic?.content;

      const name = content?.name;
      const enable = content?.enable;
      const idDialModule = content?.idDialModule;
      const id = this.$route.params.idDialModuleLine;
      const idTenant = content?.idTenant;

      return { name, id, enable, idDialModule, idTenant };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Line - ${this.instanceData.name || ""}`}
          routerKey={this.$route.name}
          actions={useLineActions(this.instanceData)}
          defaultReturnRoute={Routes.LINES_OVERVIEW}
          initDataFetch={() =>
            this.LINE_OVERVIEW_FETCH({ idDialModuleLine: this.instanceData.id })
          }
          tabs={[
            {
              title: "General",
              path: Routes.LINE_DETAIL_BASIC,
            },
            {
              title: "Monitoring",
              path: Routes.LINE_DETAIL_MONITORING,
            },
            {
              title: "Statistics",
              path: Routes.LINE_DETAIL_STATISTICS,
            },
            {
              title: "Conversation log",
              path: Routes.LINE_DETAIL_DIAL_LOGS,
            },
            {
              title: "Queue",
              path: Routes.LINE_DETAIL_LINE_QUEUE,
            },
          ]}
        />

        <CsvExportDialog
          name={Dialogs.LINE_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_REPORTS_LINE}
          prefix="line"
          asConversationSelect
        />
        <ChangeModuleDialog />
        <LineEnableDialog />
        <LineRemoveDialog />
        <DialLogDetailDialog />
      </div>
    );
  },
});

export default LineDetail;
