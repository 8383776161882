import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import UserCreateDialog from "../UserCreateDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import UserRemoveDialog from "../UserRemoveDialog";
import useUserActions from "@/hooks/useUserActions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import ChangePasswordDialog from "@/components/ChangePasswordDialog";

const UsersDataGrid = Vue.extend({
  props: {
    isInsideDetail: Boolean,
  },

  data: () => ({
    dataGridConfig: {},
    idTenant: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.USERS_DATAGRID_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Users",
      isHeaderOutside: !this.isInsideDetail,
      disableElevation: this.isInsideDetail,
      buttons: [
        {
          role: "primary",
          title: "Add user",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.USER_CREATE, {}),
          isAllowed: hasUserPermissions(Namespaces.ACCESS_MANAGEMENT, [
            Permissions.EDIT_ACCOUNT,
          ]),
        },
      ],
      rowActions: (row) => ({
        primary: {
          route: {
            name: Routes.USER_DETAIL_BASIC,
            params: { idUser: row.id },
          },
          icon: "mdi-eye",
          title: "Show detail",
          isAllowed: hasUserPermissions(
            Namespaces.ACCESS_MANAGEMENT,
            [Permissions.VIEW_ACCOUNT],
            row.idTenant
          ),
        },
        secondary: useUserActions(row),
      }),
      columnModificators: {
        "item.lastLogin": ({ item }) => formatDate(item.lastLogin),
        "item.created": ({ item }) => formatDate(item.created),
        "item.fullname": ({ item }) =>
          `${item.firstname || ""} ${item.surname || ""}`,
      },
      headers: [
        { text: "User name", value: "username" },
        { text: "Full name", value: "fullname" },
        { text: "Last login at", value: "lastLogin" },
        { text: "Created at", value: "created" },
      ],
      filters: [{ name: "username" }, { name: "fullname" }],
    };

    this.dataGridConfig = dataGridConfig;
    this.idTenant = this.$route.params.idTenant;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.USERS_DATAGRID_FETCH}
          fetchProps={{ ...(this.idTenant && { idTenant: this.idTenant }) }}
          data={this.GET_DATAGRID_DATA(DataGrids.USERS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.USERS_DATAGRID_FETCH)}
        />

        <UserCreateDialog idTenant={this.idTenant ?? null} />
        <UserRemoveDialog />
        <ChangePasswordDialog />
      </div>
    );
  },
});

export default UsersDataGrid;
