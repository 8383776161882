export const Namespaces = {
  CALLSCRIPT: "CallScript",
  COMPONENT: "Component",
  ACCESS_MANAGEMENT: "AccessManagement",
  AUDIT_LOG: "Auditlog",
  CAMPAIGN: "Campaign",
  GRAMMAR: "Grammar",
  LINE: "Line",
  MODULE: "Module",
  STATISTICS: "Statistics",
  BOTDPELOY: "BotDeploy",
  LANGUAGE: "Language",
  NLP: "NlpApi",
  TENANT_VALUE: "TenantValue",
};

export const Permissions = {
  VIEW: "view",
  DELETE: "delete",
  EDIT: "edit",
  EDIT_ACCOUNT: "editaccount",
  EDIT_ACCOUNT_PERMISSION: "editaccountpermission",
  DELETE_ACCOUNT: "deleteaccount",
  DELETE_ROLE: "deleterole",
  EDIT_ROLE: "editrole",
  VIEW_ROLE: "viewrole",
  VIEW_ACCOUNT: "viewaccount",
  VIEW_CALLSCRIPT: "viewdesign",
  EDIT_API_SECRET: "editapisecret",
  MODULE_VIEW: "moduleview",
  SERVER_VIEW: "serverview",
  CONFIG_VIEW: "configview",
  MODULE_EDIT: "moduleedit",
  SERVER_EDIT: "serveredit",
  CONFIG_EDIT: "configedit",
  TARIFF_VIEW: "viewtariff",
  TARIFF_EDIT: "edittariff",
  BILLING_VIEW: "viewbilling",
  DATASET_VIEW: "viewdataset",
  DATASET_CREATE: "createdataset",
  CATEGORY_VIEW: "viewcategory",
  CATEGORY_CREATE: "createcategory",
  MODEL_VIEW: "viewmodel",
  MODEL_CREATE: "createmodel",
  MODEL_TRAIN: "trainmodel",
  EDIT_2FA: "edit2FA",
  EDIT_TAGS: "edittags",
  VIEW_TAGS: "view-tags",
  VIEW_TAGS_ALL: "view-alltags",
  RECORD_REMOVE: "removeRecord",
  GENERATE_KEY: "generatekey",
};
