import { GetterTree } from "vuex";
import { Getters } from "@/models/store";
import UserState from "@/models/store/UserState";

const getters: GetterTree<UserState, []> = {
  [Getters.USER_INFO]: (state) => state.properties,

  [Getters.USER_ACCOUNTS]: (state) => state.connectedAcounts,

  [Getters.HAS_PERMISSIONS]:
    (state) => (requiredPermissions: string[], idTenant?: number) =>
      requiredPermissions.every((permission) => {
        if (!state.properties.permissions[permission]) {
          return false;
        }

        if (state.properties.permissions[permission].includes(-1)) {
          return true;
        }

        if (state.properties.permissions[permission] && !idTenant) {
          return true;
        }

        if (state.properties.permissions[permission] && idTenant) {
          return state.properties.permissions[permission].includes(idTenant);
        }

        return false;
      }),

  [Getters.PERMISSION_TENANTS_ID]: (state) => (permission: string) => {
    return state.properties.permissions?.[permission];
  },

  [Getters.USER_TOKEN]: (state) => state.token,

  [Getters.IS_USER_SIGNED_IN]: (state) => state.isUserSignedIn,

  [Getters.APP_CONFIG]: (state) => state.appConfig,
};

export default getters;
