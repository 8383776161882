import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import { VCard } from "vuetify/lib";
import { getTagColorClass } from "@/helpers/tagColorConfig";
import TagRemoveDialog from "../TagRemoveDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import TagCreateDialog from "../TagCreateDialog";
import Routes from "@/constants/routes";

const TagsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.TAGS_DATAGRID_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Tags",
      key: "idTag",
      isHeaderOutside: true,
      buttons: [
        {
          role: "primary",
          title: "New tag",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.TAG_CREATE, {}),
          isAllowed: hasUserPermissions(Namespaces.CALLSCRIPT, [
            Permissions.EDIT_TAGS,
          ]),
        },
      ],
      rowActions: (row) => ({
        primary: {
          icon: "mdi-delete",
          title: "Remove",
          isAllowed: hasUserPermissions(
            Namespaces.CALLSCRIPT,
            [Permissions.EDIT_TAGS],
            row.idTenant
          ),
          action: () => toggleDialog(Dialogs.TAG_REMOVE, { title: row.title }),
        },
      }),
      columnModificators: {
        "item.color": ({ item }) => (
          <VCard color={getTagColorClass(item.color)} width={30} height={30} />
        ),
        "item.tenant": ({ item }) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.TENANT_DETAIL_BASIC,
              params: { idTenant: item.idTenant },
            }}
          >
            {item.tenant}
          </router-link>
        ),
      },
      headers: [
        { text: "Name", value: "title" },
        ...(hasUserPermissions(Namespaces.CALLSCRIPT, [Permissions.EDIT_TAGS])
          ? [{ text: "Tenant", value: "tenant" }]
          : []),
        { text: "Color", value: "color" },
      ],
      filters: [{ name: "title" }],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.TAGS_DATAGRID_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.TAGS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.TAGS_DATAGRID_FETCH)}
        />
        <TagRemoveDialog />
        <TagCreateDialog />
      </div>
    );
  },
});

export default TagsDataGrid;
