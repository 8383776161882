import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import { VChip, VIcon } from "vuetify/lib";
import CAMPAIGN_STATUS from "@/constants/campaignStatus";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";
import CampaignCreateDialog from "../CampaignCreateDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import FilterExtendedDialog from "../FilterExtendedDialog";

const CampaignsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [
      Getters.GET_DATAGRID_DATA,
      Getters.GET_DATAGRID_FILTER,
    ]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    dataGridFilters(): any {
      return this.GET_DATAGRID_FILTER(DataGrids.CAMPAIGNS);
    },
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.CAMPAIGNS_DATAGRID_FETCH]),

    getPercentageColor(percentage: number): string {
      switch (true) {
        case percentage < 26:
          return "error";
        case percentage < 51:
          return "warning";
        case percentage < 76:
          return "accent";
        default:
          return "success";
      }
    },
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Campaigns",
      isHeaderOutside: true,
      buttons: [
        {
          icon: "mdi-tune",
          isPrimary: false,
          action: () => toggleDialog(Dialogs.FILTER_EXTENDED, {}),
          isFab: true,
          filterProp: "tags",
        },
        {
          role: "primary",
          title: "New campaign",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.CAMPAIGN_CREATE),
          isAllowed: hasUserPermissions(Namespaces.CAMPAIGN, [
            Permissions.EDIT,
          ]),
        },
      ],
      rowActions: ({ id, idTenant }) => ({
        primary: {
          route: {
            name: Routes.CAMPAIGN_DETAIL,
            params: { idCampaign: String(id) },
          },
          icon: "mdi-eye",
          title: "Show detail",
          isAllowed: hasUserPermissions(
            Namespaces.CAMPAIGN,
            [Permissions.VIEW],
            idTenant
          ),
        },
      }),
      columnModificators: {
        "item.statusName": ({ item }) => (
          <VChip color={CAMPAIGN_STATUS[item.status].color}>
            <VIcon left class="mr-2">
              {CAMPAIGN_STATUS[item.status].icon}
            </VIcon>
            {item.statusName}
          </VChip>
        ),

        "item.progress": ({ item }) => (
          <span
            class={`${this.getPercentageColor(
              item.progress
            )}--text font-weight-bold`}
          >
            {item.progress}%
          </span>
        ),

        "item.tenant": ({ item }) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.TENANT_DETAIL_BASIC,
              params: { idTenant: item.idTenant },
            }}
          >
            {item.tenant}
          </router-link>
        ),
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Status", value: "statusName" },
        { text: "Contact count", value: "contactCount" },
        { text: "Tenant", value: "tenant" },
        { text: "Progress", value: "progress" },
      ],
      filters: [{ name: "name" }, { name: "tenant" }],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.CAMPAIGNS_DATAGRID_FETCH}
          filterStorage={this.dataGridFilters}
          data={this.GET_DATAGRID_DATA(DataGrids.CAMPAIGNS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.CAMPAIGNS_DATAGRID_FETCH)}
        />

        <CampaignCreateDialog />
        <FilterExtendedDialog
          dataGrid={DataGrids.CAMPAIGNS}
          actionId={Actions.CAMPAIGNS_DATAGRID_FETCH}
        />
      </div>
    );
  },
});

export default CampaignsDataGrid;
