const Dialogs = {
  // Flow
  FLOW_SWITCH_VERSION: "FLOW_SWITCH_VERSION",
  FLOW_CREATE: "FLOW_CREATE",
  FLOW_VERSION_DIFF: "FLOW_VERSION_DIFF",
  FLOW_TEST_CALL: "FLOW_TEST_CALL",
  FLOW_TEST_CALL_PROGRESS: "FLOW_TEST_CALL_PROGRESS",
  FLOW_DUPLICATE: "FLOW_DUPLICATE",
  FLOW_REMOVE: "FLOW_REMOVE",
  FLOW_EDIT_BASIC: "FLOW_EDIT_BASIC",
  FLOW_CSV_EXPORT: "FLOW_CSV_EXPORT",
  FLOW_PUBLISHED_LINES: "FLOW_PUBLISHED_LINES",
  FLOW_VERSION_DUPLICATE: "FLOW_VERSION_DUPLICATE",

  // Line
  LINE_EDIT_BASIC: "LINE_EDIT_BASIC",
  LINE_EDIT_CALLBOT_CONFIG: "LINE_EDIT_CALLBOT_CONFIG",
  LINE_ENABLE: "LINE_ENABLE",
  LINE_REMOVE: "LINE_REMOVE",
  LINE_DELETE_CALL: "LINE_DELETE_CALL",
  LINE_MONITORING: "LINE_MONITORING",
  LINE_MONITORING_REMOVE: "LINE_MONITORING_REMOVE",
  LINE_MONITORING_STATE_TOGGLE: "LINE_MONITORING_STATE_TOGGLE",
  LINE_CSV_EXPORT: "LINE_CSV_EXPORT",
  LINE_CREATE: "LINE_CREATE",
  LINE_CHANGE_MODULE: "LINE_CHANGE_MODULE",
  LINE_CLEANING: "LINE_CLEANING",

  // Module
  MODULE_CREATE: "MODULE_CREATE",
  MODULE_EDIT_BASIC: "MODULE_EDIT_BASIC",
  MODULE_CALLBOT_CONFIGURATION: "MODULE_CALLBOT_CONFIGURATION",
  MODULE_CSV_EXPORT: "MODULE_CSV_EXPORT",
  MODULE_REMOVE: "MODULE_REMOVE",
  MODULE_SET_DEFAULT_LINE: "MODULE_SET_DEFAULT_LINE",

  // Grammar
  GRAMMAR_CREATE: "GRAMMAR_CREATE",
  GRAMMAR_DUPLICATE: "GRAMMAR_DUPLICATE",
  GRAMMAR_REMOVE: "GRAMMAR_REMOVE",
  GRAMMAR_EDIT_BASIC: "GRAMMAR_EDIT_BASIC",
  GRAMMAR_EDIT_CONTENT: "GRAMMAR_EDIT_CONTENT",

  // User
  USER_CREATE: "USER_CREATE",
  USER_ROLES: "USER_ROLES",
  USER_EDIT_BASIC: "USER_EDIT_BASIC",
  USER_REMOVE: "USER_REMOVE",
  USER_API_SECRET_REMOVE: "USER_API_SECRET_REMOVE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  USER_PREFERENCES: "USER_PREFERENCES",

  // Tenant
  TENANT_EDIT_BASIC: "TENANT_EDIT_BASIC",
  TENANT_EDIT_PARAMS: "TENANT_EDIT_PARAMS",
  TENANT_CREATE: "TENANT_CREATE",
  TENANT_REMOVE: "TENANT_REMOVE",
  TENANT_NOTIFICATION_GROUP: "TENANT_NOTIFICATION_GROUP",
  TENANT_NOTIFICATION_GROUP_REMOVE: "TENANT_NOTIFICATION_GROUP_REMOVE",
  TENANT_FORCE_TWO_FACTOR: "TENANT_FORCE_TWO_FACTOR",
  TENANT_STATISTICS_REPORT_EXPORT: "TENANT_STATISTICS_REPORT_EXPORT",
  TENANT_STATISTICS_OVERVIEW_EXPORT: "TENANT_STATISTICS_OVERVIEW_EXPORT",

  // Permission groups
  PERMISSION_GROUP_CREATE: "PERMISSION_GROUP_CREATE",
  PERMISSION_GROUP_REMOVE: "PERMISSION_GROUP_REMOVE",
  PERMISSION_GROUP_EDIT_BASIC: "PERMISSION_GROUP_EDIT_BASIC",
  PERMISSION_GROUP_PERMISSIONS_EDIT: "PERMISSION_GROUP_PERMISSIONS_EDIT",
  PERMISSION_GROUP_CSV_EXPORT: "PERMISSION_GROUP_CSV_EXPORT",

  // User profile
  USER_CONNECTED_ACCOUNTS: "USER_CONNECTED_ACCOUNTS",

  // Campaign
  CAMPAIGN_CREATE: "CAMPAIGN_CREATE",
  CAMPAIGN_EDIT: "CAMPAIGN_EDIT",
  CAMPAIGN_REMOVE: "CAMPAIGN_REMOVE",
  CAMPAIGN_STATE_CHANGE: "CAMPAIGN_STATE_CHANGE",
  CAMPAIGN_MANAGE_CONTACTS: "CAMPAIGN_MANAGE_CONTACTS",
  CAMPAIGN_VALIDATION_DIALOG: "CAMPAIGN_VALIDATION_DIALOG",
  CAMPAIGN_ERROR_CONTACTS: "CAMPAIGN_ERROR_CONTACTS",
  CAMPAIGN_CSV_EXPORT: "CAMPAIGN_CSV_EXPORT",
  CAMPAIGN_CSV_OVERVIEW: "CAMPAIGN_CSV_OVERVIEW",

  // Bot Deploy
  BOTDEPLOY_MODULE_CREATE: "BOTDEPLOY_MODULE_CREATE",
  BOTDEPLOY_MODULE_REMOVE: "BOTDEPLOY_MODULE_REMOVE",
  BOTDEPLOY_MODULE_EDIT_BASIC: "BOTDEPLOY_MODULE_EDIT_BASIC",
  BOTDEPLOY_MODULE_TOGGLE: "BOTDEPLOY_MODULE_TOGGLE",
  BOTDEPLOY_MODULE_RESTART: "BOTDEPLOY_MODULE_RESTART",

  BOTDEPLOY_SERVER_CREATE: "BOTDEPLOY_SERVER_CREATE",
  BOTDEPLOY_SERVER_REMOVE: "BOTDEPLOY_SERVER_REMOVE",
  BOTDEPLOY_SERVER_EDIT_BASIC: "BOTDEPLOY_SERVER_EDIT_BASIC",

  BOTDEPLOY_CONFIG_CREATE: "BOTDEPLOY_CONFIG_CREATE",
  BOTDEPLOY_CONFIG_REMOVE: "BOTDEPLOY_CONFIG_REMOVE",
  BOTDEPLOY_CONFIG_EDIT_BASIC: "BOTDEPLOY_CONFIG_EDIT_BASIC",

  // Nlp tester
  NLP_TESTER_DATASET_CREATE: "NLP_TESTER_DATASET_CREATE",
  NLP_TESTER_DATASET_REMOVE: "NLP_TESTER_DATASET_REMOVE",
  NLP_TESTER_DATASET_DUPLICATE: "NLP_TESTER_DATASET_DUPLICATE",
  NLP_TESTER_CATEGORY_CREATE: "NLP_TESTER_CATEGORY_CREATE",
  NLP_CATEGORY_SAMPLES_EDIT: "NLP_CATEGORY_SAMPLES_EDIT",
  NLP_CATEGORY_REMOVE_DIALOG: "NLP_CATEGORY_REMOVE_DIALOG",
  NLP_TESTER_MODEL_CREATE: "NLP_TESTER_MODEL_CREATE",
  NLP_TESTER_MODEL_TRAIN: "NLP_TESTER_MODEL_TRAIN",
  NLP_TESTER_MODEL_PREDICT: "NLP_TESTER_MODEL_PREDICT",
  NLP_TESTER_MODEL_REMOVE: "NLP_TESTER_MODEL_REMOVE",
  NLP_TESTER_SAMPLES_GENERATE: "NLP_TESTER_SAMPLES_GENERATE",

  // Billing
  BILLING_TENANT_SWITCH: "BILLING_TENANT_SWITCH",
  BILLING_TARIFF_EDIT: "BILLING_TARIFF_EDIT",
  BILLING_TARIFF_REMOVE: "BILLING_TARIFF_REMOVE",
  BILLING_CSV_EXPORT: "BILLING_CSV_EXPORT",

  // Language
  LANGUAGE_CREATE: "LANGUAGE_CREATE",
  LANGUAGE_EDIT_BASIC: "LANGUAGE_EDIT_BASIC",
  LANGUAGE_REMOVE: "LANGUAGE_REMOVE",

  // Variables Storage
  VARIABLE_STORAGE_CREATE: "VARIABLE_STORAGE_CREATE",
  VARIABLE_STORAGE_REMOVE: "VARIABLE_STORAGE_REMOVE",
  VARIABLE_STORAGE_EDIT: "VARIABLE_STORAGE_EDIT",
  VARIABLE_STORAGE_API_KEY: "VARIABLE_STORAGE_API_KEY",
  VARIABLE_STORAGE_TENANT_SELECT: "VARIABLE_STORAGE_TENANT_SELECT",

  // Global
  DIAL_LOG_DETAIL: "DIAL_LOG_DETAIL",
  PACKAGE_INSTALL: "PACKAGE_INSTALL",
  DIAL_LOG_AUDIO: "DIAL_LOG_AUDIO",
  DIAL_LOG_AUDIO_REMOVE: "DIAL_LOG_AUDIO_REMOVE",
  TAG_CREATE: "TAG_CREATE",
  TAG_REMOVE: "TAG_REMOVE",
  FILTER_EXTENDED: "FILTER_EXTENDED",
};

export default Dialogs;
